#galleryModal {
  height: 90vh !important;
  max-width: 100vw;
  padding: clamp(18px, 4em, 35px);
  padding: 15px;
  .body {
    height: 100%;
    .gallerySwiper {
      height: 100%;
      width: 100%;
      .swiper-slide {
        width: 100%;
        margin: auto;
        .galleryPreview {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          background-color: var(--color-gray1);
          text-align: center;
          height: 100%;
          width: 100%;
          overflow: hidden;
          position: relative;
          img {
            object-fit: contain;
            width: inherit;
            height: inherit;
          }
          iframe {
            body {
              background-color: unset;
              .toolbar {
                display: none;
              }
              #viewerContainer {
                inset: unset;
                overflow: unset;
                #viewer {
                  .page {
                    border: 0;
                  }
                }
              }
            }
          }
          .csv {
            height: 100%;
            width: 100%;
            overflow: scroll;
            table {
              height: fit-content;
            }
          }
          .icon-button {
            z-index: 1;
            position: absolute;
            bottom: 15px;
            right: 15px;
            svg {
              fill: transparent;
            }
          }
        }
      }
    }
  }
}
