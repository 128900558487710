#breadcrumb {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
  justify-content: center;
  background: var(--color-gray0);
  padding: 7px 25px;
  border-radius: 30px;
  width: fit-content;
  backdrop-filter: blur(5px);
  border: 1px solid var(--color-gray1);
  margin-left: auto;
  margin-right: auto;

  svg {
    opacity: 0.3;
    color: var(--color-text-light);
    stroke: var(--color-text-light);
  }
  .item {
    opacity: 0.6;
    font-weight: 600;
    font-size: 1.1rem;
    color: var(--color-text-light);

    &:hover {
      opacity: 1;
      color: var(--color-text);
    }
    &.selected {
      opacity: 1;
      font-weight: 700;
      color: var(--color-text);
    }
  }
}

#breadcrumb.mobile {
  position: relative;
  align-items: flex-start;
  width: fit-content;
  min-width: 200px;

  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0;

  .choices {
    backdrop-filter: blur(8px);
    position: absolute;
    z-index: 2;
    margin-top: 0;
    display: flex;
    flex-direction: column-reverse;
    background-color: var(--color-gray0);
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    width: 100%;
    top: 100%;
    &.active {
      display: flex;
    }
    &.inactive {
      display: none;
    }
    .item {
      font-weight: 500;
      border-top: 2px solid var(--color-gray3);
      justify-content: space-between;
      grid-template-columns: auto 20px;
      svg {
        color: var(--color-text);
        opacity: 0.7;
      }
      &.selected {
        display: none;
      }
    }
  }
  .item {
    width: 100%;
    opacity: 1;
    color: var(--color-text);
    margin: 0;
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
    padding: 15px;
    gap: 10px;
    &.currentLink {
      border-top: 2px solid var(--color-gray3);
      svg {
        color: var(--color-text);
        opacity: 0.7;
      }
    }
    &.selected {
      background-color: var(--color-glass);
      cursor: pointer;
      opacity: 1;
      padding: 10px 30px;
      &:hover {
        background-color: var(--color-gray2);
      }
      &.active {
        border-radius: 15px 15px 0 0;
      }
      &.inactive {
        border-radius: 30px;
      }
    }
  }
}

.dashboard:has(aside.inactive) {
  #breadcrumbs.mobile {
    visibility: hidden;
  }
}
