// NumberInput

.number-input {
  position: relative;
  width: 100%;
  max-width: 200px;
  margin: auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  background: var(--color-gray1);
  border-radius: 16px;

  .tooltip-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .minus,
  .plus {
    margin: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &:hover {
      background: var(--color-gray2);
    }
    &[disabled] {
      background: var(--color-gray3);
      &:hover {
        cursor: not-allowed;
        background: var(--color-gray3);
      }
    }
  }
  button.minus {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }
  button.plus {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  input {
    border-radius: 0px;
    appearance: inherit;
    -moz-appearance: textfield;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.disabled {
    background-color: var(--color-gray3);
    &,
    * {
      cursor: not-allowed;
    }
    .minus,
    .plus {
      cursor: not-allowed;
      &:hover {
        background: inherit;
      }
    }
  }
}
