#mes-notifications {
  .notification {
    border-bottom: 2px solid var(--color-gray2);
    padding: 15px 10px;
    border-radius: 15px;
    cursor: pointer;
    z-index: 0;
    margin-bottom: 2px;
    position: relative;
    background: var(--color-gray0);

    .readed-indication {
      position: absolute;
      top: 7px;
      right: 7px;
      height: 15px;
      width: 15px;
      background: var(--color-active);
      border-radius: 50%;
    }

    .picture-container {
      position: relative;
      .badge {
        max-width: 60px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .menu-list-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      z-index: 5;
      .menu-list {
        position: absolute;
        top: 25px;
        right: 0px;
        z-index: 5;
      }
    }

    &:hover {
      background: var(--color-gray2);
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
    .details {
      font-size: 0.95rem;
      max-width: 600px;
    }
  }
}
