.data-vizualisation-container {
  position: relative;
  border-radius: var(--size-borderRadius);
  overflow: hidden;

  .data-vizualisation {
    border: 0px solid var(--color-gray2);
    border-radius: var(--size-borderRadius);
    cursor: grab;

    .legend {
      fill: var(--color-gray25);
    }

    .connection-curve {
      stroke: var(--color-gray8);
      fill: none;
    }
  }

  .btn-container {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: fit-content;
  }
}
