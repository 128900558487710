@use "./variablesSCSS.scss";
* {
  box-sizing: border-box;

  //Scrollbar
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--color-glass);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-gray3);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray6);
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: var(--color-active) solid 2px;
  }
}

[popover] {
  &::backdrop {
    background-color: transparent;
  }
}

html {
  font-size: 95%;
  // overflow-x: hidden;
}

@mixin svgColor($color) {
  svg {
    // color: $color;
    // stroke: $color;

    :not([color="none"]) {
      color: $color;
    }
    :not([stroke="none"]) {
      stroke: $color;
    }
    // :not([fill="none"]) {
    //   fill: $color;
    // }
  }
}

body {
  background: var(--color-background1);
  // background: linear-gradient(
  //   173deg,
  //   var(--color-background1),
  //   var(--color-background2),
  //   var(--color-background3),
  //   var(--color-background4)
  // );
  // font-family: "Open Sans", sans-serif;
  font-family: "Montserrat", sans-serif;
  color: var(--color-text);
  width: 100%;
  font-weight: 500;
  min-height: 100vh;
  padding-bottom: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  transition: all 0.5s ease;
}
body:has(aside.active) {
  overflow: hidden;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 87vh;
  width: 100%;
  position: relative;
  align-items: center;
}

pre {
  white-space: pre-wrap;
  word-break: keep-all;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: var(--color-title);
  user-select: none;
  line-height: normal;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
h1 {
  font-size: clamp(2rem, 8vw, 2.7rem);
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 2rem;
  word-break: break-word;
}
h2 {
  font-size: clamp(1.25rem, 4.5vw, 1.45rem);
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 3.2rem;
}
h3 {
  font-size: clamp(1.05rem, 2vw, 1.1rem);
  font-weight: 700;
  padding: 8px 0;
  margin-bottom: 0.4rem;
  margin-top: 2rem;
  opacity: 0.8;
  color: var(--color-text);
}
h4 {
  font-size: clamp(0.9rem, 2vw, 0.95rem);
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;

  color: var(--color-gray16);
}
h5 {
  font-size: clamp(0.6rem, 1vw, 0.8rem);
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 10px;

  color: var(--color-text-soft);
}
strong {
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
}

article {
  margin-top: 20px;
  margin-bottom: 40px;

  h3 {
    margin-top: 40px;
  }
  h4 {
    margin-top: 40px;
    margin-bottom: 15px;
    padding-left: 10px;
  }
  p {
    margin-bottom: 15px;
  }
}

strong,
.strong,
.bold {
  font-weight: 600 !important;
}

ul {
  list-style: none;
  li {
    line-height: 1.4rem;
  }
  &.dotlist {
    list-style: disc;
  }
  &.checklist {
    list-style: none !important;

    li {
      margin-bottom: 10px;
      line-height: 1.4rem;
    }
    li:before {
      content: "\2714\0020";
      color: var(--color-active);
    }

    strong {
      color: var(--color-active);
    }
  }
}

ol {
  margin-top: 30px;
  margin-bottom: 40px;
  padding-left: 15px;
  list-style-type: decimal;
  list-style-position: inside;
  li {
    margin-top: 5px;
    margin-bottom: 20px;
    line-height: normal;
    &::marker {
      font-size: 1.1rem;
      font-weight: 700;
      padding-right: 15px;
    }
  }
}

a {
  margin: 5px 0;
  // font-size: 0.95rem;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-text);
}

a.link {
  color: var(--color-main);
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: var(--color-active);
  }
}

p {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0rem;
  margin-bottom: 8px;
  line-height: normal;
  color: var(--color-text-light);
}

em {
  font-style: italic;
  color: var(--color-text-light);
  font-weight: 400;
  font-size: 0.9rem;
  line-height: inherit;
  word-wrap: break-all;
  display: inline-block;
}

hr {
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: var(--color-gray3);
  border: none;
  background-color: var(--color-text);
  opacity: 0.1;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

@include svgColor(inherit);

/////////////////////////////////////////////////////////////////////
/// FORMS
////////////////////////////////////////////////////////////////////

form {
  width: 100%;
  max-width: var(--size-input);
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  .form-buttons {
    button {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

fieldset {
  padding: 5px 20px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  margin-bottom: 10px;
  border: 2px solid var(--color-gray1);

  legend {
    padding: 10px;
    font-size: 0.95rem;
    font-weight: 500;
    color: var(--color-text-light);
  }
}

input,
select,
textarea,
.select-input .value,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
// input:-internal-autofill-selected,
input:-webkit-autofill,
.select-input .option,
.number-input .minus,
.number-input .plus {
  font-family: "Montserrat", sans-serif;
  -webkit-background: 0 0 0 30px var(--color-gray1) inset !important;
  border: 1px solid var(--color-gray2);
  font-size: 1rem;

  color: var(--color-text);
  background: var(--color-gray1);
  border-radius: 16px;
  padding: 14px 14px;
  resize: none;
  width: 100%;
  &::placeholder {
    font-family: "Open Sans", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
  }

  // &:focus {
  //   border: 1px solid var(--color-active);
  // }
}

.input-container {
  max-width: var(--size-input);
  .number-input {
    margin-top: 0;
  }
  &.small {
    padding: 2px 5px;
    margin: 0;
    input,
    textarea,
    .select-input .value,
    .select-input .option {
      padding: 5px 10px;
      border-radius: 8px;
      font-size: 0.85rem;
    }
    .number-input {
      border-radius: 5px;
    }
    .select-input {
      font-size: 0.85rem;
    }
  }
  &:has(label) {
    .select-input {
      padding-top: 0px;
    }
  }

  select,
  .select-input {
    min-height: 49px;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-size: 0.95rem;
    position: relative;
    font-weight: 500;
    .value {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg {
        margin-right: 10px;
      }
    }
    .clickable:hover {
      background-color: var(--color-gray2);
      border-color: var(--color-gray2);
    }
    &:has(.options.visible) {
      .value {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        // border-color: var(--color-gray3) !important;
      }
    }
    width: 100%;
    .options {
      max-height: 250px;
      overflow-y: auto;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-color: var(--color-gray3);
      .option {
        width: 100%;
        &.selected {
          background-color: var(--color-gray2);
          &.clickable:hover {
            background-color: var(--color-gray3);
          }
        }
        &:not(:last-of-type) {
          border-radius: 0px;
        }
        &:last-of-type {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
    &.disabled {
      *,
      & {
        cursor: not-allowed;
      }
      .value {
        background: var(--color-gray3);
      }
    }
  }
}
.circle {
  border-radius: 500px;
}
select {
  font-family: "Open Sans", sans-serif;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 0.95rem;
  &.small {
    padding: 5px 10px;
    font-size: 0.85rem;
  }
}

label {
  font-size: 0.95rem;
  color: var(--color-text-light);
  font-weight: 600;
  opacity: 0.8;
}

input:disabled,
textarea:disabled,
select:disabled {
  background: var(--color-gray3);
  *,
  & {
    cursor: not-allowed;
  }
}

.previews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 10px;
  justify-content: center;
  .preview {
    max-width: 150px;
    width: 100%;
    aspect-ratio: 3/2;
    position: relative;
    text-align: center;

    .img-container {
      background-color: var(--color-gray1);
      border-radius: 20px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

/////////////////////////////////////////////////////////////////////
/// TABLES
////////////////////////////////////////////////////////////////////

table {
  width: 100%;
  background: var(--color-gray0);
  border-collapse: separate;
  border-radius: 14px;
  border: 1px solid var(--color-gray2);
  overflow: hidden;

  &.small {
    thead {
      tr {
        th {
          font-size: 0.8rem;
          padding-top: 8px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 0.8rem;
          * {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  tr {
    width: 100%;
    background: var(--color-gray0-transparent);
  }

  thead {
    tr {
      border-radius: 10px;
      overflow: hidden;
      background: var(--color-blue-light);
      font-weight: 600;

      th {
        background: var(--color-blue-light);
        font-size: 0.9rem;
        font-weight: 600;
        padding: 12px;

        text-align: left;
        vertical-align: middle;
        svg {
          margin-left: 10px;
        }
      }
    }
  }
  tbody {
    tr {
      th {
        font-weight: 600;
        text-align: left;
        padding: 8px;
      }

      &:last-child {
        td {
          padding-bottom: 6px;
        }
      }

      &:nth-child(even) {
        background: var(--color-blue-light);
      }
      &:hover:nth-child(even) {
        background: var(--color-blue-light-hover);
      }
      &:hover:nth-child(odd) {
        background: var(--color-blue-light-hover);
      }

      &.fail {
        color: var(--color-error);
        background: var(--color-errorBackground);
        &:hover {
          background: var(--color-errorBackground-hover);
        }
      }
      &.success {
        color: var(--color-success);
        background: var(--color-successBackground);
        &:hover {
          background: var(--color-successBackground-hover);
        }
      }
      &.warning {
        color: var(--color-warning);
        background: var(--color-warningBackground);
        &:hover {
          background: var(--color-warningBackground-hover);
        }
      }
    }
    td {
      font-size: 0.88rem;
      padding: 3px 8px;
      vertical-align: middle;
      &:first-child {
        padding-left: 12px;
      }
      .badge.small {
        padding: 2px 5px;
        font-size: 0.8rem;
        font-weight: 500;
      }

      &.right {
        display: flex;
        margin-left: auto;
        justify-content: flex-end;
        > * {
          display: block;
        }
      }

      a.link {
        font-size: 0.9rem;
      }
    }
  }

  &.empty {
    background: none;
    border: none;

    thead {
      border-radius: 0;
      overflow: visible;
      tr {
        background: none;
        padding: 7px 2px;
        th {
          background: none;
          border-bottom: 1px solid var(--color-gray3);
          padding: 7px 2px;
        }
      }
    }
    tbody {
      tr {
        border-radius: 0px;
        background: none;
      }
      td {
        padding: 7px 2px;
        background: none;

        border-bottom: 1px solid var(--color-gray2);
      }
    }
  }

  &.transparent,
  &.transparent2 {
    background: none;
    border: none;
    border-radius: unset;
    thead {
      tr {
        background: none;
        th {
          background: none;
          border-bottom: 1px solid var(--color-gray3);
          padding-bottom: 5px;
          padding-top: 10px;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }
      }
    }
    tbody {
      tr {
        border-collapse: separate;

        background: none;
        &:hover {
          background: none !important;
        }
        td {
          background: none;
        }

        &:last-of-type td {
          border: none;
        }
      }
    }
  }

  &.transparent2 {
    thead {
      tr {
        th {
          background: var(--color-blue-light);
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid var(--color-gray2);
        }
        &:last-of-type td {
          border: none;
        }
        &:hover {
          td {
            background: var(--color-blue-light-hover);
          }
        }
      }
    }
  }

  &.with-border {
    border-color: var(--color-gray5);
    td {
      border-left: 1px solid var(--color-gray5);
      border-bottom: 1px solid var(--color-gray5);
      &:first-of-type {
        border-left: none;
      }
    }
    tr:last-of-type td {
      border-bottom: none;
    }
  }

  &.not-clickable {
    tr {
      &:hover:nth-child(odd) {
        background: var(--color-gray0-transparent);
      }
      &:hover:nth-child(even) {
        background: var(--color-gray1);
      }
    }
  }
}

@media (max-width: variablesSCSS.$media-small-screen) {
  form {
    padding-inline: 0;
  }

  table {
    th {
      display: none;
    }
    tbody {
      tr {
        &:nth-child(even) {
          background: var(--color-gray1);
        }

        td {
          padding-inline: 8px !important;
          display: flex;
          align-items: center;
          justify-content: flex-start !important;
          text-align: left !important;
          justify-content: flex-start !important;
          gap: 10px;
          padding: 5px;
          margin: 0 !important;
          width: 100% !important;
          &:first-child {
            padding-top: 15px;
          }
          &:last-child {
            padding-bottom: 15px;
          }

          &::before {
            content: attr(datatype);
            font-weight: 700;
          }

          > div,
          > .m-auto,
          > .tooltip-container > .m-auto {
            margin-left: 0px !important;
            &:not(:last-child) {
              margin-right: 0px !important;
            }
            &:last-child {
              margin-right: auto !important;
            }
          }
        }
      }
    }
  }
}
