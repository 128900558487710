#ticket,
#tickets-creation {
}

#tickets-list {
  border: 1px solid var(--color-gray2);
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 0;

  #header {
    position: relative;
  }
  #body {
    min-height: 40vh;
    max-height: 70vh;
    height: 100%;
    overflow-y: auto;
  }
  .ticket {
    position: relative;
    padding: 5px;
    padding-left: 10px;
    width: 100%;
    border-bottom: 2px solid var(--color-gray1);
    overflow: hidden;

    cursor: pointer;
    &:hover {
      background: var(--color-gray2);
    }

    &.selected {
      background: var(--color-gray2);
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

#tickets-creation {
  .tile-choice {
    height: 100%;
    span {
      font-size: 0.85rem;
    }
  }

  #form {
    .input-container {
      max-width: unset;
    }
    textarea {
      width: 100%;
      max-width: unset;
    }
  }
}

.report-attachments {
  .preview {
    position: relative;
    .close-btn {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 30px;
      height: 30px;
      border-radius: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-gray1);
      cursor: pointer;
      &:hover {
        background-color: var(--color-gray2);
      }
    }

    span {
      font-size: 0.75rem;
    }
  }
}

#ticket {
  min-height: 80vh;
  max-width: 1300px;

  margin: auto;

  #historic {
    .item {
      margin-top: 0px;
      border-radius: 15px;
      padding: 5px;

      &.clickable:hover {
        background: var(--color-gray1);
      }
      .legend {
        font-size: 0.9rem;
        color: var(--color-gray18);
        font-weight: 600;
      }

      .date {
        font-size: 0.8rem;
        color: var(--color-gray10);
        font-style: italic;
        margin-top: 5px;
      }
      .circle {
        width: 45px;
        height: 45px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  #conversation-wrapper {
    min-height: 500px;
    max-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;

    .modal {
      .body {
        height: 100%;
        overflow: hidden;
        .icon-button {
          position: absolute;
          right: 15px;
          bottom: 0px;
        }
        iframe {
          height: 100%;
          width: 100%;
          body {
            background-color: unset;

            .toolbar {
              display: none;
            }
            #viewerContainer {
              inset: unset;
              overflow: unset;
              #viewer {
                .page {
                  border: 0;
                }
              }
            }
          }
        }
      }
    }

    .message-wrapper {
      margin-bottom: 35px;
      display: flex;
      gap: 15px;

      .attachments {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5px;
        margin-top: 5px;
        margin-bottom: 15px;
        .attachment {
          width: 80px;
          height: 55px;
          overflow: hidden;
          border-radius: 10px;
          cursor: pointer;
          background: var(--color-gray1);
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            filter: brightness(90%);
          }
        }
      }

      &.revert {
        flex-direction: row-reverse;

        .message {
          border-radius: 28px 5px 28px 28px;
        }
        .attachments {
          flex-direction: row-reverse;
        }
        .column {
          align-items: flex-end;
        }
      }
      .column {
        width: 100%;
        align-items: flex-start;
      }
      figure {
        aspect-ratio: 1/1;
      }
      .message {
        background: var(--color-gray1);
        padding: 22px 28px;
        border-radius: 5px 28px 28px 28px;
        width: fit-content;
        max-width: 70%;

        .name {
          font-size: 1rem;
          font-weight: 800;
          margin-bottom: 15px;
        }
        .content {
          font-size: 0.95rem;
          word-break: break-word;
          max-width: 500px;
          pre {
            white-space: break-spaces;
            line-height: normal;
          }
        }

        &.me {
          color: white;
          .content {
            color: white;
          }
          background: var(--color-active);
        }
      }
    }
  }

  textarea {
    width: 100%;
    max-width: unset;
    padding: 15px;
    height: unset;
    border-radius: 18px;
    border: none;
    field-sizing: content;
    background: var(--color-gray1);
  }
  .input-container {
    padding: 0;
    max-width: unset;
  }
}
