#month-swiper {
  cursor: grabbing;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 5px;

  .swiper-wrapper {
    align-items: center;
  }

  .month {
    padding: 0px 40px;
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.3s ease;
    color: var(--color-gray6);
    text-align: center;
    text-transform: capitalize;

    &.selected {
      font-size: 1.9rem;
      font-weight: 700;
      color: var(--color-text);
    }
  }
}
