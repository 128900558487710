.chevron-list-link {
  .title {
    font-size: 1.05rem;
    font-weight: 500;
    color: var(--color-gray12);
  }
  .wrapper {
    background: var(--color-gray0-transparent);
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    .item {
      margin: 0 0;
      gap: 10px;
      padding: 15px;
      width: 100%;
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 20px;
      border-bottom: 1px solid var(--color-gray1);

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:last-child {
        border: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .icon {
    fill: var(--color-active);
    background: var(--color-blue-light);
    border-radius: 6px;
    aspect-ratio: 1/1;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: var(--color-text-light);
      opacity: 0.7;
    }
  }

  .item.clickable:hover {
    cursor: pointer;
    background: var(--color-gray2);
    .icon {
      background: var(--color-blue-light);
      svg {
        opacity: 1;
        fill: var(--color-active);
      }
    }
  }
}
