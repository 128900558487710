.textarea-input {
  position: relative;
  overflow: "hidden";
  .validation {
    top: 10px;
    position: absolute;
    right: 10px;

    fill: var(--color-success);
  }
  textarea {
    overflow: "hidden";
    resize: "none";
    margin: 0px;
  }
}
