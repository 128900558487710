.back-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-inline: 10px;
  padding: 5px 15px 5px 5px;
  border-radius: 80px;
  width: fit-content;

  .icon {
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-gray1);

    border-radius: 50px;
    border: 1px solid var(--color-gray2);
  }

  &.clickable:hover {
    background: var(--color-gray1);
    .icon {
      border-color: var(--color-gray3);
      background: var(--color-gray2);
    }
  }
}
