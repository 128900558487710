.expertCreate,
.expertUpdate,
.expertDeny,
.companyAnswer,
.companyComplete {
  p.content {
    text-align: end;
    margin: 0px !important;
  }

  .previews {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-left: auto;
    .preview,
    .preview-container {
      .img-container {
        aspect-ratio: 3/2;
        max-width: 50px;
        height: auto;
      }
    }
  }
}
