.btn-container {
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr;

  > *:not(.modal),
  button:not(.button--close) {
    width: 100% !important;
    height: 100% !important;
    max-width: unset !important;
    margin: unset !important;
  }
}
