.user-header {
  height: 100%;

  .profil-picture {
    position: relative;

    .bubble {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
