#mot-de-passe-oublie {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tile {
    position: relative;
    padding: 5rem 8rem;
    .top {
      margin: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      button {
        margin: 0;
      }
    }

    h1 {
      margin-bottom: 3rem;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
    }
    p {
      text-align: center;
    }

    form {
      margin: 0;
      button {
        width: 100%;
      }
      input {
        max-width: 100%;
      }
    }
  }
}
