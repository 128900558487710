#exercice_pairingKeys {
  display: flex;
  align-items: center;

  .shape {
    margin-left: -10px;
    &:first-child {
      margin-left: 0px;
    }
  }
}

@media (max-width: 1200px) {
  #exercice_pairingKeys {
    flex-direction: column;
    .shape {
      margin-left: 0px;
      margin-top: -10px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
}
