.toggle-item {
  display: flex;
  margin: 7px 0;
  gap: 10px;
  align-items: center;
  max-width: var(--size-input);
  cursor: pointer;
  > label {
    margin-bottom: 0;
  }

  // ToggleInput

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin: 0;
    border-radius: 0px;
    padding: 0px;
    flex-shrink: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-gray4);
      transition: 0.2s;
      border-radius: 24px;
      cursor: pointer;
      // &:hover {
      // }
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 75%;
      width: 45%;
      left: 7%;
      bottom: 13%;
      background-color: var(--color-gray0-transparent);
      transition: 0.4s;
      border-radius: 50%;
    }

    input:checked + .slider {
      background-color: var(--color-main);
    }

    input:checked + .slider:before {
      transform: translateX(90%);
    }
  }

  &.warning {
    input:checked + .slider {
      background-color: var(--color-warning);
    }
  }
  &.success {
    input:checked + .slider {
      background-color: var(--color-success);
    }
  }
  &.error {
    input:checked + .slider {
      background-color: var(--color-error);
    }
  }
  &.info {
    input:checked + .slider {
      background-color: var(--color-info);
    }
  }
  &.yellow {
    input:checked + .slider {
      background-color: var(--color-yellow);
    }
  }
  &.violet {
    input:checked + .slider {
      background-color: var(--color-violet);
    }
  }
  &.orange {
    input:checked + .slider {
      background-color: var(--color-orange);
    }
  }

  &.small {
    .switch {
      width: 29px;
      height: 18px;
    }
  }

  &.big {
    .switch {
      width: 55px;
      height: 32px;
    }
  }

  &:not(.disabled):hover .slider {
    cursor: pointer;
    background-color: var(--color-gray5);
  }

  &.disabled {
    &,
    * {
      cursor: not-allowed !important;
    }

    input:checked + .slider {
      background-color: var(--color-gray25);
    }

    label {
      opacity: 0.5;
    }
    .switch {
      input .slider {
        background-color: var(--color-gray15);
        &:before {
          background-color: var(--color-gray4);
        }
      }
    }
  }
}
