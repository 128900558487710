@use "variablesSCSS.scss";

.row {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px;
}
.column {
  display: flex !important;
  flex-direction: column !important;
  margin-top: 0;
}
.row-reverse {
  justify-content: flex-end;
  display: flex !important;
  flex-direction: row-reverse !important;
}
.column-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}
.grid {
  display: grid !important;
}
.flex {
  display: flex !important;
}
@for $i from 0 through 100 {
  .grow-#{$i} {
    flex-grow: #{$i} !important;
  }
  .shrink-#{$i} {
    flex-shrink: #{$i} !important;
  }
}
.wrap {
  flex-wrap: wrap !important;
}
.nowrap {
  flex-wrap: nowrap !important;
}
.space-between {
  justify-content: space-between !important;
}
.space-around {
  justify-content: space-around !important;
}
.j-center,
.center {
  justify-content: center !important;
}
.j-start {
  justify-content: flex-start !important;
}
.j-end {
  justify-content: flex-end !important;
}
.fit-content {
  width: fit-content !important;
}
.max-content {
  width: max-content !important;
}
.min-content {
  width: min-content !important;
}

.item-center,
.a-center {
  align-items: center !important;
}
.item-stretch,
.a-stretch {
  align-items: stretch !important;
}
.flex-end,
.a-end {
  align-items: flex-end !important;
}

.j-end {
  justify-content: end !important;
}

.flex-start,
.a-start {
  align-items: flex-start !important;
}

.text-uppercase {
  text-transform: uppercase;
}
.m-05 {
  margin: 5px !important;
}
.ml-05 {
  margin-left: 5px !important;
}
.mr-05 {
  margin-right: 5px !important;
}
.mt-05 {
  margin-top: 5px !important;
}
.mb-05 {
  margin-bottom: 5px !important;
}

.m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}

.v-center {
  vertical-align: middle;
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
  top: 80px;
  z-index: 10;
  backdrop-filter: blur(8px);
}

@media (max-width: variablesSCSS.$media-small-screen) {
  .sticky {
    position: unset;
    top: 0;
  }
}

@for $i from 0 through 100 {
  .w-#{$i} {
    width: calc(#{$i} * 1%) !important;
  }
  .h-#{$i} {
    height: calc(#{$i} * 1%) !important;
  }
}

@for $i from 0 through 200 {
  .mw-#{calc($i*10)} {
    max-width: calc(#{$i} * 10px) !important;
  }
  .mh-#{calc($i*10)} {
    max-height: calc(#{$i} * 10px) !important;
  }
}

.mh-max {
  max-height: 100% !important;
}

@for $i from 0 through 500 {
  .font-size-#{$i},
  .fs-#{$i} {
    font-size: clamp(
      calc(#{$i} * 0.01rem * 2 / 3),
      calc(#{$i} * 0.05vw),
      calc(#{$i} * 0.01rem)
    ) !important;
  }
}

.mw-unset {
  max-width: unset !important;
}
.m-unset {
  width: unset !important;
}
.h-unset {
  height: unset !important;
}

@for $i from 0 through 9 {
  .gap-#{$i}5 {
    gap: calc(#{$i} * 10px + 5px) !important;
  }
  .fw-#{$i}00 {
    &,
    * {
      font-weight: #{$i}00 !important;
    }
  }
}

.pt-05 {
  padding-top: 5px !important;
}
.pb-05 {
  padding-bottom: 5px !important;
}
.pl-05 {
  padding-left: 5px !important;
}
.pr-05 {
  padding-right: 5px !important;
}
.p-05 {
  padding: 5px !important;
}

@for $i from 0 through 10 {
  .gap-#{$i} {
    gap: calc(#{$i} * 10px) !important;
  }
}

@for $i from 0 through 20 {
  .br-#{$i} {
    border-radius: calc(#{$i} * 10px) !important;
  }

  .mt-#{$i} {
    margin-top: calc(#{$i} * 10px) !important;
  }
  .mb-#{$i} {
    margin-bottom: calc(#{$i} * 10px) !important;
  }
  .ml-#{$i} {
    margin-left: calc(#{$i} * 10px) !important;
  }
  .mr-#{$i} {
    margin-right: calc(#{$i} * 10px) !important;
  }
  .m-#{$i} {
    margin: calc(#{$i} * 10px) !important;
  }

  .pt-#{$i} {
    padding-top: calc(#{$i} * 10px) !important;
  }
  .pb-#{$i} {
    padding-bottom: calc(#{$i} * 10px) !important;
  }
  .pl-#{$i} {
    padding-left: calc(#{$i} * 10px) !important;
  }
  .pr-#{$i} {
    padding-right: calc(#{$i} * 10px) !important;
  }
  .p-#{$i} {
    padding: calc(#{$i} * 10px) !important;
  }
  .bottom-#{$i} {
    bottom: calc(#{$i} * 10px) !important;
  }
  .right-#{$i} {
    right: calc(#{$i} * 10px) !important;
  }
  .left-#{$i} {
    left: calc(#{$i} * 10px) !important;
  }
  bottom-#{$i} {
    bottom: calc(#{$i} * 10px) !important;
  }
  .top-#{$i} {
    top: calc(#{$i} * 10px) !important;
  }
  .top--#{$i} {
    top: calc(#{$i} * -10px) !important;
  }
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
  display: none !important;
}

.crossed {
  text-decoration: line-through;
}
.ellipsis {
  display: block !important;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  padding-block: 1px;
}
.capitalize {
  text-transform: capitalize;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.o-hidden {
  overflow: hidden;
}
.o-auto {
  overflow: auto;
}
.o-visible {
  overflow: visible;
}

.number-bubble {
  background: var(--color-text);
  color: var(--color-white);
  padding: 3px 5px;
  border-radius: 10px;
  bottom: 0;
  right: 0;
  font-size: 0.75rem;
  transform: translate(40%, 30%);
}

@media (max-width: variablesSCSS.$media-medium-screen) {
  .mh-max {
    max-height: unset !important;
  }
}
