#error {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 700px;
  .tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }
}
