#presentation-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 80px !important;
  &.page {
    margin: unset;
    max-width: unset;
  }
  .tile {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 585px;
    min-height: 400px;
    border-radius: 40px !important;
  }

  #logo {
    height: 50px;
    width: unset;
  }
  img {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  #icon {
    background: var(--color-background1);
    width: clamp(150px, 15vw, 240px);
    height: clamp(150px, 15vw, 240px);
    border-radius: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: clamp(75px, 7.5vw, 120px);
      height: auto;
    }
  }

  h2 {
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: clamp(1.5rem, 10vw, 3rem);
  }
  p {
    font-size: 1.05rem;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 450px;
  }
  button {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
}
