#swiper-container {
  width: 100%;

  &.swiper {
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 5% 95%,
      transparent 100%
    );
    padding: 0 20px;
    position: relative;
    overflow: hidden;

    .swiper-button-next,
    .swiper-button-prev {
      color: var(--color-title);
      position: absolute;
      font-size: small;
      &::after {
        font-size: 1.5rem;
      }
    }
    .swiper-button-next {
      right: 10px;
    }
    .swiper-button-prev {
      left: 10px;
    }
    .swiper-slide {
      width: fit-content;
    }
    svg {
      height: 20px;
      width: 20px;
    }
    .swiper-button-disabled {
      color: transparent;
    }
  }
}
