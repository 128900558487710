@use "./variablesSCSS.scss";
.dashboard {
  width: 99%;
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: flex-start;
  column-gap: 40px;
  margin: auto;
  padding: 10px 15px 50px;
  min-height: 100vh;
  position: relative;
  margin-bottom: 20px;

  header {
    width: 100%;
    position: sticky;
    top: 18px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  // //// NOTIFICATION //////////////////////
  .header-btn {
    background: var(--color-gray0);
    &:hover {
      background: var(--color-gray2);
    }
  }
  .window-header {
    position: fixed;
    top: 82px;
    right: 25px;

    padding: 15px 12px 12px;
    background: var(--color-gray0);
    border-radius: 20px;
    width: 350px;
    z-index: 10;
    filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1));
  }
  #notification-btn {
    background: var(--color-gray0);

    &:hover {
      background: var(--color-gray2);
    }
  }
  #notification-btn-container {
    #number {
      background: var(--color-error);
      color: white;

      min-width: 25px;
      min-height: 25px;
      padding: 5px;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
      font-size: 0.8rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  #notification-window {
    position: fixed;
    top: 82px;
    right: 25px;

    padding: 15px 12px 12px;
    background: var(--color-gray0);
    border-radius: 20px;
    width: 350px;
    z-index: 10;
    filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1));

    #configuration-btn {
      position: absolute;
      top: 10px;
      right: 10px;

      .menu-list {
        top: -20px;
        right: 0px;
      }
    }

    #list {
      max-height: 50vh;
      overflow-y: auto;
      border: 2px solid var(--color-gray1);
      border-radius: 20px;

      .has-more {
        color: var(--color-gray10);
        width: 96%;
        margin: 20px auto;
        font-size: 0.9rem;
      }

      .notification {
        overflow: visible;
        border-radius: 15px;
        padding: 10px 15px 10px 7px;
        position: relative;

        .configuration-btn {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          background: var(--color-gray2);
        }
        .title {
          font-size: 0.9rem;
          font-weight: 700;
          margin-bottom: 5px;
          color: var(--color-text-light);
        }

        .details {
          font-weight: 500;
          font-size: 0.85rem;
        }
        .icons-communicationtype {
          position: absolute;
          bottom: 5px;
          right: 5px;
          display: flex;
          justify-content: flex-start;
          gap: 2px;
        }

        .readed-indication {
          position: absolute;
          top: 6px;
          right: 6px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: var(--color-active);
        }

        .picture-container {
          position: relative;
          .badge {
            position: absolute;
            bottom: -6px;
            right: -6px;
            .circle {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }

  // //// NOTIFICATION //////////////////////
  #menuBurger-btn,
  #close-btn {
    display: none;
  }

  aside {
    width: 100%;
    padding: 0px 0px 20px;
    height: 100%;
    max-width: 300px;

    .title {
      margin: 0px;
      width: 100%;
      font-size: 0.85rem;
      font-weight: 700;
      padding: 13px 15px 7px;
      color: var(--color-text-light);
      opacity: 1;
      text-transform: uppercase;
    }

    nav {
      padding: 0px;
      display: flex;
      flex-direction: column;
    }

    .header {
      width: 100%;
      padding: 10px;
      p {
        font-size: 0.95rem !important;
        color: var(--color-gray18);
        font-weight: 600 !important;
      }
      svg {
        fill: var(--color-gray18);
      }

      .icon {
        padding: 10px;
        border-radius: 40px;
        background: var(--color-gray1);
      }
    }

    .add-company-btn {
      .chevronlink {
        margin-block: 0 !important;
        padding-left: 15px !important;
        // border-radius: 0 !important;
      }
    }

    .company-item {
      display: grid;
      grid-template-columns: 25px 1fr 20px;
      border-bottom: 1px solid var(--color-gray2);
      padding: 3px 3px 3px 10px;
      gap: 10px;
      align-items: center;
      cursor: pointer;

      font-size: 0.85rem;
      font-weight: 600;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: var(--color-gray1);
      }
    }

    .company-selected-wrapper {
      background: var(--color-gray0-transparent);
      border: none;
      margin: 0;
      max-width: unset;
      border-radius: 0;
      padding-top: 6px;
      padding-inline: 5px;
    }

    #company-selected,
    #accountingfirm-selected {
      display: grid;
      margin: 0;
      max-width: 290px;
      width: fit-content;

      align-items: center;
      gap: 10px;
      border-radius: 10px;
      padding: 5px 20px 5px 5px;
      border: 1px solid var(--color-gray2);
      font-size: 0.9rem;
      font-weight: 600;
      grid-template-columns: auto 1fr;
      text-align: left;
      border-radius: 80px;
      width: 100%;

      &.clickable:hover {
        cursor: pointer;
        background: var(--color-gray1);
      }
    }

    #accountingfirm-selected {
      margin: 6px 5px 5px;
    }

    .tile {
      width: 100%;
      padding: 0;

      overflow-x: visible;
      overflow-y: auto;
      margin-top: 20px;
    }
    .user.tile {
      overflow: visible;
      padding: 10px;
    }

    hr {
      margin: 0;
    }

    #logo {
      width: 60px;
      > * {
        position: unset !important;
      }
    }

    #espace {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      text-align: left;
      width: 100%;
      margin-bottom: 0;
    }

    .user {
      display: grid;
      grid-template-columns: 42px 1fr 40px;
      gap: 10px;
      align-items: center;
      margin-top: 0px;
      margin-bottom: 0px;
      position: relative;
      padding: 3px 3px;

      border-radius: 12px;
      font-size: 0.9rem;

      &:hover {
        .icon-button {
          display: block;
        }
      }

      .profil-container {
        position: relative;
        .modifyInformation {
          display: none;
          position: absolute;
          background: var(--color-gray3);
          right: -5px;
          bottom: -5px;

          justify-content: center;
          align-items: center;
          &:hover {
            background: var(--color-gray3);
          }
        }
      }
    }

    .name {
      font-size: 0.9rem;
      font-weight: 600;
      margin-top: 0px;
    }
    .role {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--color-gray12);
    }
    .email {
      font-weight: 500;
      color: var(--color-text-light);
      font-size: 0.75rem;
      text-overflow: ellipsis;
      word-break: break-all;
      display: none;
    }

    nav {
      height: 100%;
      width: 100%;
      .closeButton {
        display: none;
      }
      ul {
        border-radius: 10px;
        padding: 0px 0;

        li {
          width: 100%;
          .navlink {
            width: 100%;
          }
        }
      }

      .sublink-container {
        display: flex;
        flex-direction: column;
        margin: 5px 5px;
        .sublink {
          padding: 7px;
          margin: 0;
          font-size: 0.85rem;
          font-weight: 600;
          border-left: 2px solid var(--color-gray1);

          &:hover {
            background-color: var(--color-gray2);
          }
          &.selected {
            background: var(--color-blue-light);
            color: var(--color-active);
            svg {
              color: var(--color-active);
              stroke: var(--color-active);
            }
          }
        }
      }
    }

    #companies {
      margin: 0rem 0;
      background: none;
      width: 100%;
      position: sticky;
      top: 20px;
      overflow-y: hidden;
      overflow-x: hidden;
      .title {
        display: flex;
        align-items: center;
        .indication-icon {
          margin-right: 0.5rem;
        }
      }

      #aside-company-search {
        height: 100%;
        .companies-list {
          overflow-y: auto;
          max-height: 50vh;
        }
      }

      > .companies-wrapper {
        display: flex;
        .menu-wrapper {
          display: flex;
        }
      }
    }
  }

  &:has(aside.active) {
    #scroll-btn,
    #breadcrumb {
      visibility: hidden !important;
    }
  }
}

@media (max-width: variablesSCSS.$media-small-screen) {
  .dashboard {
    display: block;
    padding: 10px;

    aside {
      display: none;
    }
  }

  #menuBurger {
    z-index: 60;
  }
  #close-burger-btn {
    z-index: 61;
  }

  #menuBurger-btn,
  #close-burger-btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 15px;
    right: 50%;
    transform: translate(50%);
    border-radius: 50%;
    height: 57px;
    width: 57px;
    background: var(--color-active);
    overflow: hidden;

    .icon-button {
      background: var(--color-active);
    }
    svg,
    path {
      color: white;
      stroke: white;
    }
  }
}
