.accountingFirmBadge,
.userBadge,
.companyBadge {
  display: flex;
  gap: 10px;
  align-items: center;
  user-select: none;
  border-radius: 50px;
  width: fit-content;

  &.clickable:hover {
    background-color: var(--color-gray3) !important;
  }
  &.selected {
    background-color: var(--color-active) !important;
    &.clickable:hover {
      background-color: var(--color-gray3) !important;
    }
  }
  &.deleted {
    opacity: 0.6;
  }
}
