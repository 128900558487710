.roles-container,
.choice-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.role,
.choice-container > .item {
  border: 1px solid var(--color-gray3);
  border-radius: 50px;
  padding: 5px;
  font-weight: 600;
  display: flex;
  font-size: 0.85rem;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: fit-content;
  &:has(.legend) {
    padding: 5px 20px 5px 5px;
  }
  &.shape,
  .shape {
    background-color: var(--color-gray2) !important;
  }
  .legend {
    margin: 0;
    font-size: 0.9rem;
  }
  &.selected {
    background: var(--color-infoBackground);
    border-color: var(--color-info);
    &.shape,
    .shape {
      background-color: var(--color-info) !important;
    }
    svg {
      color: white !important;
    }
    .legend {
      color: var(--color-info);
    }
  }
  &.clickable {
    cursor: pointer;
    &:hover {
      background: var(--color-gray1);
      &.shape,
      .shape {
        background-color: var(--color-gray3) !important;
      }
    }
    &.selected {
      &:hover {
        background: var(--color-infoBackground-hover);
        .shape {
          background-color: var(--color-info-hover) !important;
        }
      }
    }
  }

  &.small {
    font-size: 0.8rem;
    padding: 3px;
    &.shape,
    .shape {
      width: 20px !important;
      height: 20px !important;
    }
  }

  &.normal {
    padding: 5px;
  }

  &.big {
    font-size: 1.1rem;
    padding: 8px;
    &.shape,
    .shape {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.category-choicer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  .icon {
    margin: 0px;
    padding: 10px;
    border-radius: 7px;
    &.selected {
      background-color: var(--color-blue-background);
    }
    &:hover {
      background-color: var(--color-gray2);
    }
  }
}

.company-permission {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  aspect-ratio: 1/1;

  @for $i from 0 through 100 {
    &.w-#{$i} {
      width: #{$i}px !important;
      svg {
        width: calc(#{$i}* 0.7px) !important;
        height: auto;
      }
    }
  }

  &.admin {
    background-color: var(--color-infoBackground);
    color: var(--color-info);
  }
  &.owner {
    background-color: var(--color-warningBackground);
    color: var(--color-warning);
  }
}
