.searchInputWithResults {
  .results {
    .item {
      cursor: pointer;
      &:hover {
        background: var(--color-gray2);
      }
    }
  }
}
