#toast-container {
  width: 100%;
}
.toast {
  display: grid;
  grid-template-columns: auto 1fr 50px;
  gap: 14px;
  overflow: hidden;
  border: var(--color-gray4) solid 0px;
  border-radius: 20px;
  font-weight: 500;
  left: unset;
  margin-block: 15px;
  margin-right: 10px;
  filter: drop-shadow(5px 2px 4px rgba(0, 0, 0, 0.05));
  max-width: 400px;
  width: 85%;
  position: fixed;
  padding: 20px 18px;

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0px;
    height: 5px;
    background-color: var(--color-glass);
    border-bottom: solid var(--color-gray0-transparent) 1px;

    .progress-bar {
      height: 100%;
      width: 100%;
      margin-right: auto;
    }
  }

  .button--close {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;
  }

  .description {
    margin-top: 6px;
    font-size: 1.05rem;
  }

  &.info {
    background-color: var(--color-infoBackground);
    color: var(--color-info);
    border-color: var(--color-info);
    .progress-bar {
      background-color: var(--color-info);
    }
    * {
      color: var(--color-info);
    }
    svg {
      stroke: var(--color-info) !important;
    }
  }
  &.fail {
    background-color: var(--color-errorBackground);
    border-color: var(--color-error);
    color: var(--color-error);
    * {
      color: var(--color-error);
    }
    .progress-bar {
      background-color: var(--color-error);
    }
    svg {
      stroke: var(--color-error) !important;
    }
  }
  &.good {
    background-color: var(--color-successBackground);
    color: var(--color-success);
    border-color: var(--color-success);
    * {
      color: var(--color-success);
    }
    .progress-bar {
      background-color: var(--color-success);
    }
    svg {
      stroke: var(--color-success) !important;
    }
  }
  &.warning {
    background-color: var(--color-warningBackground);
    color: var(--color-warning);
    border-color: var(--color-warning);
    * {
      color: var(--color-warning);
    }
    .progress-bar {
      background-color: var(--color-warning);
    }
    svg {
      stroke: var(--color-warning) !important;
    }
  }
}
