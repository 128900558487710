.chevronlink {
  display: grid;
  align-items: center;
  margin: 0;
  padding: 20px 15px 20px 25px;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  gap: 10;
  cursor: pointer;

  &.empty {
    background: none;
    border: 1px solid var(--color-gray2) !important;
  }
  &.gray-light {
    background: var(--color-gray1);
  }

  .icon {
    color: var(--color-active);

    background: var(--color-blue-light);
    aspect-ratio: 1/1;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 200px;

    svg {
      fill: var(--color-active);
      opacity: 0.7;
    }
  }

  &:hover {
    background: var(--color-gray2);
    .icon {
      background: var(--color-active);
      svg {
        opacity: 1;
        fill: var(--color-white);
      }
    }

    &.clickable.empty {
      background-color: var(--color-gray1);
    }
  }

  p,
  span,
  a {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    line-height: 1rem;
    font-size: 0.94rem;
    font-weight: 600;
    svg {
      margin-right: 20px;
    }
  }
  .children {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
