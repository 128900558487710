.filter-bar {
  display: flex;
  // justify-content: space-between;

  &.column {
    flex-direction: column;

    button {
      margin-top: 5px;
    }
  }
  &.row {
    align-items: center;
    flex-direction: row;
    gap: 20px;

    button {
      margin: 0px;
    }
  }

  label {
    font-size: 0.85rem;
  }

  > div.button-container {
    justify-content: space-between;
    // height: 100%;
  }

  .search-container {
    div {
      height: 100%;
      justify-content: space-between;
    }
  }

  .input-container {
    margin-block: 0;
    padding-block: 0;
    gap: 4px;
    .select-input {
      margin-block: 0;
      padding-block: 0;
    }
  }
}
