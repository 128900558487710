.tooltip-container {
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  max-width: 100%;

  .tooltip {
    font-size: 0.9rem !important;
    white-space: break-spaces;
    background-color: var(--color-text);
    color: var(--color-gray0);
    border-radius: 25px;
    padding: 8px 16px;
    font-weight: 600;
    position: absolute;
    z-index: 50;
    max-width: 250px;
    width: max-content;
    text-align: center;
    overflow: hidden;
    opacity: 0;

    animation: fadeIn 0.2s ease-in;
    animation-fill-mode: both;

    @keyframes fadeIn {
      from {
        visibility: hidden;
        //  transform: translate(0, 0);
        opacity: 0;
      }
      to {
        visibility: visible !important;
        // transform: translate(20, 0);
        opacity: 1;
      }
    }
    p,
    em,
    span,
    strong {
      color: var(--color-gray0);
    }
    p {
      font-size: 0.85rem;
      font-weight: 600;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
    strong {
      font-size: 0.9rem;
    }

    .tile {
      &.empty {
        border-color: var(--color-gray7);
      }
      &.gray-light {
        background-color: var(--color-gray10);
      }
      &.gray-light {
        background-color: var(--color-gray8);
      }
    }

    &::after {
      content: "";
      position: absolute;
      border-width: 6px;

      border-style: solid;
    }

    &.top {
      transform: translateX(-50%) translateY(-100%);
      &::after {
        border-color: var(--color-gray20) transparent transparent transparent;

        top: 100%;
        right: calc(50% - 7px);
      }
    }

    &.bottom {
      transform: translateX(-50%);
      &::after {
        border-color: transparent transparent var(--color-gray20) transparent;

        bottom: 100%;
        right: calc(50% - 7px);
      }
    }

    &.left {
      transform: translateX(-100%) translateY(-50%);
      &::after {
        border-color: transparent transparent transparent var(--color-gray20);

        left: 100%;
        bottom: calc(50% - 5px);
      }
    }

    &.right {
      transform: translateY(-50%);
      &::after {
        border-color: transparent var(--color-gray20) transparent transparent;

        right: 100%;
        bottom: calc(50% - 5px);
      }
    }
  }
}

@media (hover: none) and (pointer: coarse) {
  /* Styles pour appareils tactiles (smartphones/tablettes) */
  .tooltip {
    display: none !important;
  }
}
