@use "ui/styles/commons/commons.scss";

.tile-choice {
  border: 2px solid transparent;
  background: var(--color-gray1);
  gap: 10px;
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  color: var(--color-gray12);
  @include commons.svgColor(inherit);

  //   > :not(
  //     .shape,
  //     .shape *,
  //     .badge,
  //     .badge *,
  //     button,
  //     button *,
  //     .tooltip,
  //     .tooltip *,
  //     .modal,
  //     .modal *
  //   ) {
  //   * {
  //     color: inherit;
  //   }
  // }

  .img-container {
    background: var(--color-gray2);
  }

  &.white {
    background: var(--color-gray0);
  }

  &.clickable:hover {
    cursor: pointer;
    background: var(--color-blue-light);

    .img-container {
      background: var(--color-blue-light-hover);
      border-color: var(--color-blue-light-hover);
      .cls-1 {
        fill: var(--color-gray1);
      }
    }
  }

  &.empty {
    background: transparent;
    border-color: var(--color-gray5);
    cursor: pointer;

    color: var(--color-active);
    @include commons.svgColor(var(--color-active));

    .img-container {
      background: var(--color-gray1);
    }

    &.clickable:hover {
      background: var(--color-gray2);

      .img-container {
        background: var(--color-gray3);
        .cls-1 {
          fill: var(--color-gray4);
        }
      }
    }
  }

  &.selected {
    background-color: var(--color-active);
    color: white;
    cursor: pointer;
    > svg {
      :not([color="none"]) {
        color: white;
      }
      :not([stroke="none"]) {
        stroke: white;
      }
      :not([fill="none"]) {
        fill: white;
      }
    }

    > :not(
        .shape,
        .shape *,
        .badge,
        .badge *,
        button,
        button *,
        .tooltip,
        .tooltip *,
        .modal,
        .modal *
      ) {
      // * {
      color: inherit;
      // }
    }

    .shape:not([style*="background"]) {
      background-color: var(--color-main);

      @include commons.svgColor(white);
    }

    .img-container {
      background: var(--color-main);

      * {
        color: var(--color-white);
      }
    }

    &:focus-visible {
      outline-color: var(--color-text-light);
    }
    &.clickable:hover {
      background: var(--color-main) !important;

      .shape:not([style*="background"]) {
        background-color: var(--color-active);
      }

      .img-container {
        background: var(--color-active);

        * {
          color: var(--color-white);
        }
        .cls-1 {
          fill: var(--color-infoBackground);
        }
      }
    }
  }
}
