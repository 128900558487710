@use "./../../styles/commons/variablesSCSS.scss";

.modal {
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: var(--color-gray0);
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  border: none;
  top: 0;
  left: 0;
  min-height: 200px;
  z-index: 10;
  position: fixed;
  height: fit-content;
  min-height: 200px;
  max-height: 90vh;
  max-width: 900px;

  * {
    cursor: auto;

    &.clickable,
    &.clickable * {
      cursor: pointer;
    }
  }

  &::backdrop {
    background-color: rgba(187, 187, 187, 0.226);
    backdrop-filter: blur(3.5px);
  }

  h2 {
    font-size: clamp(1.3rem, 4vw, 1.8rem);
  }
  form {
    h2 {
      font-weight: 600;
    }
  }

  .header {
    z-index: 15;
    position: absolute;
    right: 10px;
    top: 10px;

    border-radius: 50%;
    width: 45px;
    height: 45px;
    cursor: pointer;

    background: var(--color-glass);

    &:hover {
      background: var(--color-gray1);
    }
  }
  .body {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: fit-content;
    padding: 25px 40px 20px;
    display: flex;
    flex-direction: column;
  }
}

.modal#send_confirmation_modal {
  min-height: 80vh;
  .body {
    // justify-content: center;
    min-height: 50vh !important;
  }
}

@media (max-width: variablesSCSS.$media-tablet) {
  .modal {
    width: 100% !important;
    max-width: 98vw !important;
    max-height: 99vh !important;
    padding: 0;

    .content {
      padding: 20px 10px 5px;
    }
  }
}

@media (max-width: variablesSCSS.$media-small-screen) {
  .modal {
    .body {
      overflow-y: scroll !important;
      .mh-max {
        max-height: unset;
      }
      .o-hidden {
        overflow: unset;
      }
    }
  }
}

// @media (max-width: variablesSCSS.$media-tablet) {
//   .modal {
//     position: fixed;
//     left: 0;
//     z-index: 10;
//     top: 0;
//     width: 100vw;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .dark-background {
//       position: fixed;
//       z-index: 1;
//       width: 100vw;
//       height: 100vh;
//       background-color: rgba(187, 187, 187, 0.226);
//       backdrop-filter: blur(3.5px);
//     }
//     .content {
//       position: relative;
//       z-index: 11;
//       border-radius: 15px;
//       background-color: var(--color-gray0);
//       padding: clamp(8px, 2em, 18px);
//       padding-top: 20px;
//       width: 95vw;
//       max-width: 900px;
//       max-height: 80vh;
//       display: flex;
//       flex-direction: column;

//       h2 {
//         font-size: clamp(1.3rem, 4vw, 1.8rem);
//       }
//       form {
//         h2 {
//           font-weight: 600;
//         }
//       }

//       .header {
//         z-index: 11;
//         position: absolute;
//         right: 10px;
//         top: 10px;
//         padding: 5px;
//         border-radius: 50%;
//         cursor: pointer;
//         width: unset;
//         background: var(--color-glass);

//         &:hover {
//           background: var(--color-gray1);
//         }
//       }
//       .body {
//         overflow-x: hidden;
//         overflow-y: auto;
//       }
//     }
//   }
// }
