#connexion {
  width: clamp(250px, 95vw, 600px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    border-radius: 40px !important;

    filter: drop-shadow(0px 19px 30px rgba(0, 0, 0, 0.062));
  }

  h1 {
    margin-bottom: 3rem;
    font-size: clamp(2.1rem, 12vw, 2.5rem);
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: clamp(250px, 85vw, 450px);
    margin: unset;
  }
  button {
    width: 100%;
    margin: 0.5rem 0;
    grid-template-columns: auto auto;
  }

  #forgetted-password {
    margin-top: 2.5rem;
    text-align: center;
    span {
      font-style: italic;
      color: var(--color-text-light);
      margin-right: 1rem;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
}
