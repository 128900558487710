@use "./variablesSCSS.scss";

/////////////////////////////////////////////////////////////////////
/// BLOCS
////////////////////////////////////////////////////////////////////

.dotlist {
  list-style: disc;
  padding-left: 20px;
  li {
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
}

.page {
  width: 100%;
  min-height: 70vh;
  max-width: 1500px;
  margin: auto;
  margin-top: 15px;

  h1 {
    margin-bottom: 0.3rem;
  }
  .subtitle {
    font-weight: 600;
    font-size: clamp(1.1rem, 4vw, 1.5rem);
    font-family: var(--font-title);
    margin-top: 0.7rem;
    margin-bottom: 2rem;
    line-height: 1.8rem;
  }
}

.page-form {
  margin: auto;
  max-width: var(--size-input);
}

.container {
  width: 90%;
  max-width: 900px;
  margin: auto;
}

.clickable {
  &,
  * {
    cursor: pointer;
  }
}

.unclickable {
  cursor: auto !important;

  &:hover {
    background-color: unset !important;
  }
}

.cache {
  position: absolute;
  inset: 0;
  background-color: color-mix(in hsl, var(--color-text), transparent 75%);
}

.not-allowed,
.not-allowed * {
  cursor: not-allowed !important;
}

////////////////////////////////////////////////////////////////////
/// IMAGE
///////////////////////////////////////////////////////////////////

.img-container {
  overflow: hidden;
  background-color: var(--color-gray1);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.img-container:has(.without-image) {
  display: flex;
  align-items: center;
  justify-content: center;
  .without-image {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    text-align: center;
    height: inherit;

    .shape {
      aspect-ratio: 1/1;
      width: 30% !important;
      margin: auto;
      background: var(--color-infoBackground);
      svg {
        color: var(--color-active);
        width: 80%;
        height: auto;
        margin: auto;
      }
    }
    p {
      padding-inline: 10px;
      padding-top: 5%;
      font-size: 0.8rem;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.preview,
.preview-container {
  position: relative;
  .img-container {
    aspect-ratio: 3/2;
    &.clickable:hover {
      background-color: var(--color-gray2);
    }
  }
  .icon-button {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
  }
}

/////////////////////////////////////////////////////////////////////
/// TEXTS
////////////////////////////////////////////////////////////////////

.list {
  max-height: 400px;
  overflow-y: auto;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right !important;
  * {
    text-align: right !important;
  }
}

.subtitle {
  font-size: clamp(1.1rem, 4vw, 1.5rem);
  font-weight: 600;
  opacity: 0.8;
  color: var(--color-text-light);
}

@media (max-width: variablesSCSS.$media-phone) {
  .tile {
    padding: 10px 10px;
  }
}
