.picture-group {
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;

  .img-container {
    border-width: 2px !important;
    border-color: var(--color-gray0-transparent);
  }

  > :not(:first-child) {
    margin-left: -6px !important;
  }

  :first-child {
    margin-left: 0px !important;
  }

  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-active);
    border-radius: 50%;
    color: var(--color-infoBackground);
    border: 2px solid var(--color-active);
    font-size: 0.85rem;
    font-weight: 700;
  }
}
