.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  &.skeleton-container {
    .skeleton {
      position: relative;
      overflow: hidden;
      background-color: var(--color-gray2);
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: shimmer 1.25s infinite;
        content: "";
      }

      @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
    }
    &.picture {
      @for $i from 0 through 50 {
        &.s-#{$i} {
          width: calc(#{$i}* 5px) !important;
          height: calc(#{$i}* 5px) !important;
        }
      }
      * {
        width: 100%;
        height: 100%;
        &.square {
          border-radius: 8px;
        }
        &.circle {
          border-radius: 70px;
        }
      }
    }

    &.text {
      width: 100%;

      @for $i from 0 through 100 {
        &.w-#{$i} {
          width: calc(#{$i}* 10px) !important;
        }
      }

      @for $i from 0 through 1000 {
        &.h-#{$i} {
          height: calc(#{$i}px) !important;
        }
      }
    }

    * {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  &.circle {
    circle.secondary {
      stroke: var(--color-gray3);
    }
    circle:not(.secondary) {
      stroke: var(--color-active);
    }
  }
}
