.tile {
  width: 100%;
  border-radius: var(--size-borderRadius);
  background: var(--color-gray0-transparent);
  padding: 10px 20px;
  margin: 6px auto 6px;

  &.modification {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 30px;
    .title {
      font-size: 0.75rem;
      font-weight: 600;
      margin-bottom: 2px;
      margin-top: 5px;
      color: var(--color-gray10);
      text-transform: uppercase;
    }
    .value {
      font-size: 0.9rem;
      margin-bottom: 5px;
      background: none;
    }
  }

  &.clickable:hover {
    background: var(--color-account);
  }

  @for $i from 0 through 100 {
    &.w-#{$i} {
      width: calc(#{$i} * 1%);
    }
  }
  //  COLORS
  &.blue {
    background-color: var(--color-account);

    input,
    textarea,
    select {
      font-weight: 500;
      background: none;
      border: 1px solid var(--color-gray3);
    }

    h2,
    h3,
    h4,
    h5 {
      color: var(--color-link);
      margin-left: 5px;
      opacity: 0.9;
      margin-bottom: 0px;
    }
    h3 {
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 18px;
      font-size: clamp(1rem, 3vw, 1.2rem);
      font-weight: 600;
      color: var(--color-link);
      opacity: 0.85;
    }
    &.clickable:hover {
      background: var(--color-blue-light-hover);
    }
  }

  &.error {
    background: var(--color-errorBackground);
    h2,
    p,
    h3,
    span {
      color: var(--color-error);
    }
  }

  &.darkBlue {
    background: var(--color-link);
    color: var(--color-gray0);
    &.clickable:hover {
      background: var(--color-active);
      color: var(--color-gray0);
    }
  }

  &.lightblue {
    background: var(--color-infoBackground);
    // color: var(--color-gray0);
    &.clickable:hover {
      background: var(--color-infoBackground-hover);
      // color: var(--color-gray0);
    }
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-left: 5px;
  }

  .btn {
    width: 100%;
    border-radius: 10px;
    background: none;
    color: var(--color-text);
    margin: 0;
    border: var(--color-text);
    font-weight: 600;
    svg {
      color: var(--color-text);
      stroke: var(--color-text);
      path {
        color: var(--color-text);
        stroke: var(--color-text);
      }
    }
    &:hover {
      background: var(--color-gray4);
    }
  }

  .line {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    &,
    * {
      font-size: 0.9rem;
    }
  }

  h2:first-child,
  h3:first-child,
  h1:first-child,
  h4:first-child {
    margin-top: 0;
  }

  .modal-tile {
    padding: 1rem;
    margin: 0;
  }

  .title {
    font-size: clamp(0.9rem, 3vw, 1.05rem);
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &.empty {
    background: none;
    border: 1px solid var(--color-gray2);
    padding: 10px;
    h3 {
      padding-top: 0;
    }

    &.selected {
      border-color: var(--color-active);
    }
  }
  &.transparent {
    background: none;
    border: none;
  }
  &.gray {
    background: var(--color-gray2);

    &.clickable:hover {
      background: var(--color-gray3);
      .img-container {
        background: var(--color-gray4);
      }
    }
  }
  &.gray-light {
    background: var(--color-gray1);

    &.clickable {
      &:hover {
        background: var(--color-gray2);
      }
    }
  }
  &.blue-light {
    background: var(--color-blue-light);

    &.clickable {
      &:hover {
        background: var(--color-blue-light-hover);
      }
    }
  }

  &.modification.mobile {
    .title {
      margin-right: 50px;
    }
  }

  &.disabled {
    cursor: auto;
    background: var(--color-gray1);

    // &:not(.tooltip),
    &:not(.tooltip) {
      opacity: 0.6;
      // color: var(--color-gray9);
      // stroke: var(--color-gray9);
    }
  }

  //  SIZE
  &.small {
    max-width: 500px;
  }

  &.medium {
    max-width: 800px;
  }

  &.thin {
    padding: 8px;
  }
}
