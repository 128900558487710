.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 0.9rem;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.pagination-choicer {
  display: flex;
  gap: 10px;
  margin: 20px auto 5px;
  width: fit-content;

  .pagination-item {
    padding: 10px 15px;
    border-bottom: 3px solid transparent;
    border-radius: 500px;
    padding: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-gray1);
    cursor: pointer;
    &:hover {
      background: var(--color-gray2);
    }
    &.selected {
      background-color: var(--color-main);
      color: var(--color-gray0);
      cursor: auto;
    }
    &.dots {
    }
  }
}
