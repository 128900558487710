@use "./../../styles/commons/variablesSCSS.scss";

.picture {
  position: relative;
  user-select: none;

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-blue-light);
    border-radius: 50%;
    position: relative;
    aspect-ratio: 1;
    border: 1px solid var(--color-gray3);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .text-placeholder {
      font-weight: 700;
      text-transform: "uppercase";

      color: var(--color-active);
      font-size: clamp(0.8rem, 1rem, 2rem);
    }
    .cache {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--color-text);
      opacity: 0.2;
      display: none;
    }
    > svg {
      margin: 0 !important;
      width: 55%;
    }
  }

  .icon {
    position: absolute;
    z-index: 10;
    opacity: 1;
    &.modify {
      top: 50%;
      left: 50%;
      transform: translate((-50%, -50%));
      color: var(--color-gray0);
      display: none;
    }
    &.company-permission {
      right: 0px;
      bottom: 0px;
      transform: translate(10%, 10%);
    }
  }

  &:hover {
    .cache {
      display: block;
    }
    .icon {
      &.modify {
        display: block;
      }
    }
  }
}

.placeholder {
  .cls-1,
  .cls-2,
  .cls-3,
  circle {
    fill: var(--color-text-soft);
  }
}

.profil-picture {
  .img-container:has(.text-placeholder) {
    background: var(--color-infoBackground);
    border-color: var(--color-gray0-transparent);
  }
}

@media (max-width: variablesSCSS.$media-medium-screen) {
  .picture {
    .icon:not(.company-permission) {
      background-color: var(--color-gray1);
      display: block;
      left: unset;
      top: unset;
      bottom: 0;
      right: 0;
      transform: none;
      color: var(--color-gray8);
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 50px;
      z-index: 1;
    }
  }
}
