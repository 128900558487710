.pes-visualizer {
  height: 10px;
  margin: 0px;
  width: 100%;
  max-width: 170px;

  .part {
    width: 100%;
    height: 100%;
    background: var(--color-gray4);
    opacity: 0.8;
  }
}
