//// SEARCH
.search-bold {
  background: var(--color-main);
  color: white;
}

aside {
  .search-input {
    padding: 10px;
  }
}

.search-input {
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.small {
    padding: 8px;
    .input {
      svg {
        left: 8px;
        top: 8px;
      }
      input {
        padding: 8px;
        padding-left: 35px;
        font-size: 0.9rem;
      }
    }
  }

  .loader {
    position: absolute;
    right: 55px;
    top: -5px;
    svg {
      left: unset;
      top: unset;
    }
  }

  input {
    max-width: unset;
    border-radius: 25px;
  }
  .input {
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      left: 15px;
      top: 13px;
    }
    input {
      padding-left: 45px;
      width: 100%;
    }
  }
}

.search-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  justify-content: flex-end;
  align-items: end;

  label {
    margin-bottom: 2px;
  }
  .column {
    position: relative;
  }
  .input-container,
  .select-input {
    margin-block: 0px;
    padding-block: 0px;
  }

  .search-suggestions {
    position: absolute;
    overflow-y: auto;
    width: 100%;
    padding: 10px 0px 0px;
    top: calc(100% - 5px);
    background-color: var(--color-gray1);
    border-radius: 0 0 var(--size-borderRadius) var(--size-borderRadius);
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.062));
    z-index: 11;
    height: fit-content;
    max-height: 20vh;

    .suggestion {
      padding: 8px min(40px, 10%) 8px;
      cursor: pointer;
      &:hover {
        background: var(--color-gray2);
      }
    }
    .message {
      padding: 0px 40px 20px;
    }
  }

  &:has(.search-suggestions) {
    input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

@media (max-width: --media-phone) {
  .search-container {
    grid-template-columns: 1fr !important;
    #criteria {
      order: 1;
    }
    .column {
      order: 2;
    }
  }
}
