@use "../../../styles/commons/variablesSCSS.scss";
#scroll-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 3;

  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--color-active);
  overflow: hidden;
  filter: drop-shadow(0px 3px 10px var(--color-shadow));
  padding: 18px;
  width: 100%;
  max-width: 55px;

  svg {
    color: white;
    stroke: white;
    fill: white;
    width: 100%;
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }
}

@media (max-width: variablesSCSS.$media-small-screen) {
  #scroll-btn {
    bottom: 80px;
  }
}

@media (max-width: variablesSCSS.$media-tablet) {
  #scroll-btn {
    bottom: 145px;
  }
}
