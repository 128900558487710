.question-exchange-icon {
  width: 100%;
  border-radius: 15px;
  border: 1px solid var(--color-gray2);

  svg,
  .tooltip-container {
    margin: auto;
  }
}
