#deconnexion {
  .tile {
    position: fixed;
    top: 45%;
    transform: translateY(-50%);
    max-width: 550px;
    border-radius: 40px !important;
    filter: drop-shadow(0px 19px 30px rgba(0, 0, 0, 0.062));
  }
}
