// PasswordInput

.password-container {
  .password-valid {
    position: relative;
    .eye {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
    .confirmation {
      position: absolute;
      top: 50%;
      right: 45px;
      transform: translateY(-50%);
    }
  }

  .password-info {
    border-radius: 8px;
    background-color: var(--color-gray1);
    padding: 0.2rem;
    margin-top: 0.5rem;
    max-width: var(--size-input);
    ul {
      li {
        display: flex;
        align-items: center;
        padding: 0.3rem;
        gap: 0.25rem 0.5rem;
        font-size: 0.87rem;
        font-weight: 500;
      }
    }
  }
  .valid {
    color: var(--color-success);
    fill: var(--color-success);
  }
  .unvalid {
    color: var(--color-error);
    fill: var(--color-error);
  }
}
