// CheckboxInput
.checkbox.input-container {
  .checkbox-input {
    margin: 0px;
    grid-template-columns: auto;
    cursor: pointer;
    border-radius: 5px;
    padding: 2px 2px 1px 2px;
    svg {
      width: 100%;
      height: auto;
      path {
        fill: var(--color-gray0);
      }
    }
    &.disabled {
      background-color: var(--color-gray7) !important;
      &,
      * {
        cursor: not-allowed;
      }
      svg {
        path {
          fill: var(--color-gray0);
        }
      }
    }
  }
  &.row,
  &.row-reverse {
    align-items: center;
  }
}
