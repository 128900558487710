.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  border: none;
  margin: 0;
  width: fit-content;
  height: fit-content;
  padding: 8px;

  svg {
    margin: 0;
  }

  &.square {
    border-radius: 12px;
  }
  &.circle {
    border-radius: 500px;
  }

  &.transparent {
    background-color: inherit;
    border: 2px solid var(--color-gray1);
    color: var(--color-gray15);
    font-weight: 600;
    svg {
      color: var(--color-gray10);
      stroke: var(--color-gray10);
    }
    &.clickable:hover {
      background-color: var(--color-gray2);
      border: 2px solid var(--color-gray2);
    }
  }
}
