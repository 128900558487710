@use "ui/styles/commons/commons.scss";

button {
  user-select: none;
  border: 1px solid transparent;
  margin: 0px;
  padding: 0px;
  background-color: transparent;

  color: inherit;
  font-family: var(--font-main);

  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: normal;
  gap: 10px;
}

button.button,
button.icon-button {
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 20px;

  max-width: var(--size-input);

  &.button {
    margin: 24px auto;
  }
  svg {
    flex-shrink: 0;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////// TYPES //////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  @mixin buttonType($name, $fontWeight: null, $color: null, $backgroundColor, $colorHover: null, $backgroundColorHover) {
    &.#{$name} {
      background: $backgroundColor;

      @if $fontWeight {
        font-weight: $fontWeight;
      }

      @if $color {
        color: $color;
        svg {
          color: $color;

          circle:not(.secondary) {
            stroke: $color;
          }
        }
      }

      &.clickable:hover {
        background-color: $backgroundColorHover;

        @if $colorHover {
          color: $colorHover;
          @include commons.svgColor($colorHover);

          svg {
            color: $colorHover;

            circle:not(.secondary) {
              stroke: $colorHover;
            }
          }
        }
      }
    }
  }

  ////////////////////////////////////////////

  @include buttonType($name: "primary", $color: white, $backgroundColor: var(--color-active), $backgroundColorHover: var(--color-main));

  &.primary {
    &.clickable:focus-visible {
      outline-color: var(--color-text-light);
    }
  }

  ////////////////////////////////////////////

  @include buttonType($name: "primary-light", $fontWeight: 500, $color: var(--color-main), $backgroundColor: var(--color-blue-light), $backgroundColorHover: var(--color-blue-light-hover));

  ////////////////////////////////////////////

  @include buttonType($name: "white", $color: var(--color-gray15), $backgroundColor: var(--color-gray0-transparent), $backgroundColorHover: var(--color-gray3));

  ////////////////////////////////////////////

  @include buttonType($name: "transparent", $color: var(--color-gray15), $backgroundColor: unset, $backgroundColorHover: var(--color-gray2));
  &.transparent {
    border: 1px solid var(--color-gray2);
    &.clickable:hover {
      border: 1px solid var(--color-gray3);
    }
  }

  ////////////////////////////////////////////

  @include buttonType($name: "dark", $color: var(--color-gray0), $backgroundColor: var(--color-text), $backgroundColorHover: var(--color-text-light));

  ////////////////////////////////////////////

  @include buttonType($name: "error,&.red", $fontWeight: 500, $color: var(--color-errorBackground), $backgroundColor: var(--color-error), $backgroundColorHover: var(--color-error-hover));

  ////////////////////////////////////////////

  @include buttonType(
    $name: "error-light,&.red-light",
    $fontWeight: 500,
    $color: var(--color-error),
    $backgroundColor: var(--color-errorBackground),
    $backgroundColorHover: var(--color-errorBackground-hover)
  );

  ////////////////////////////////////////////

  @include buttonType($name: "secondary", $fontWeight: 500, $color: var(--color-white), $backgroundColor: var(--color-secondary), $backgroundColorHover: var(--color-secondary-hover));

  ////////////////////////////////////////////

  @include buttonType(
    $name: "secondary-light",
    $fontWeight: 500,
    $color: var(--color-secondary),
    $backgroundColor: var(--color-secondary-background),
    $backgroundColorHover: var(--color-secondary-background-hover)
  );

  ////////////////////////////////////////////

  @include buttonType(
    $name: "gray",
    $fontWeight: 500,
    $color: var(--color-gray17),
    $backgroundColor: var(--color-gray2),
    $colorHover: var(--color-gray19),
    $backgroundColorHover: var(--color-gray3)
  );

  ////////////////////////////////////////////

  @include buttonType($name: "gray-light", $fontWeight: 500, $color: var(--color-gray13), $backgroundColor: var(--color-gray1), $backgroundColorHover: var(--color-gray2));

  ////////////////////////////////////////////

  @include buttonType(
    $name: "warning,&.yellow",
    $fontWeight: 500,
    $color: var(--color-warningBackground),
    $backgroundColor: var(--color-warning),
    $backgroundColorHover: var(--color-warning-hover)
  );

  ////////////////////////////////////////////

  @include buttonType(
    $name: "warning-light,&.yellow-light",
    $fontWeight: 500,
    $color: var(--color-warning),
    $backgroundColor: var(--color-warningBackground),
    $backgroundColorHover: var(--color-warningBackground-hover)
  );

  ////////////////////////////////////////////

  @include buttonType(
    $name: "success,&.green",
    $fontWeight: 500,
    $color: var(--color-successBackground),
    $backgroundColor: var(--color-success),
    $backgroundColorHover: var(--color-success-hover)
  );

  ////////////////////////////////////////////

  @include buttonType(
    $name: "success-light,&.green-light",
    $fontWeight: 500,
    $color: var(--color-success),
    $backgroundColor: var(--color-successBackground),
    $backgroundColorHover: var(--color-successBackground-hover)
  );

  ////////////////////////////////////////////

  @include buttonType($name: "info,&.blue", $fontWeight: 500, $color: var(--color-infoBackground), $backgroundColor: var(--color-info), $backgroundColorHover: var(--color-info-hover));

  ////////////////////////////////////////////

  @include buttonType(
    $name: "info-light,&.blue-light",
    $fontWeight: 500,
    $color: var(--color-info),
    $backgroundColor: var(--color-infoBackground),
    $backgroundColorHover: var(--color-infoBackground-hover)
  );

  ////////////////////////////////////////////

  @include buttonType($name: "empty", $color: inherit, $backgroundColor: transparent, $backgroundColorHover: var(--color-darken0));

  ////////////////////////////////////////////

  @include buttonType($name: "darken", $color: inherit, $backgroundColor: var(--color-darken0), $backgroundColorHover: var(--color-darken1));

  ///////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////// SIZES //////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////

  @mixin buttonSize($name, $fontSize, $fontWeight: null, $lineHeight, $borderRadius, $paddingInline, $paddingBlock, $paddingRight: null, $paddingLeft: null) {
    &.#{$name} {
      font-size: $fontSize;
      @if $fontWeight {
        font-weight: $fontWeight;
      }

      border-radius: $borderRadius;
      line-height: $lineHeight;

      padding: $paddingBlock $paddingInline;

      @if $paddingRight {
        padding-right: $paddingRight;
      }
      @if $paddingLeft {
        padding-right: $paddingLeft;
      }
    }
  }

  ////////////////////////////////////////////

  @include buttonSize($name: "small", $fontSize: 0.9rem, $lineHeight: 1rem, $borderRadius: 10px, $paddingBlock: 9px, $paddingInline: 10px, $paddingRight: 14px);

  ////////////////////////////////////////////

  @include buttonSize($name: "medium", $fontSize: 0.95rem, $lineHeight: 1rem, $fontWeight: 600, $borderRadius: 14px, $paddingBlock: 16px, $paddingInline: 30px);

  ////////////////////////////////////////////

  @include buttonSize($name: "big", $fontSize: 1rem, $fontWeight: 600, $lineHeight: 1.1rem, $borderRadius: 19px, $paddingBlock: 25px, $paddingInline: 60px);

  ///////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////// FORMS //////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////

  &.circle {
    border-radius: 50px;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////// DISABLED ////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////

  &.disabled,
  &[disabled] {
    opacity: 0.5;

    &,
    *,
    path {
      cursor: not-allowed;
    }
  }
}
