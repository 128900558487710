.company-header {
  height: 100%;

  .company-picture {
    position: relative;
    .bubble {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .info {
    h3 {
      margin: 0;
      padding: 0;
    }
    em {
      font-size: 0.8rem;
      word-break: break-all;
    }
  }
}
