.menu-list-container {
  position: relative;
  width: fit-content;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    height: auto;
    width: fit-content;
    padding: 10px;
    border-radius: 50px;
    border: 1px solid var(--color-text-soft);
    opacity: 1;

    &.clickable {
      &:hover {
        opacity: 1;
        background: var(--color-text);
        svg {
          color: var(--color-gray0);
        }
      }
    }
  }

  .menu-list {
    position: absolute;
    top: 50%;
    right: 50%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    border-radius: 16px;
    overflow: hidden;
    background: var(--color-gray0);
    filter: drop-shadow(0px 8px 10px #00000046);
    z-index: 3;

    li {
      font-size: 0.9rem;
      font-weight: 600;
      padding: 12px 15px;

      display: flex;
      align-items: center;
      white-space: nowrap;
      width: 100%;
      svg {
        margin-right: 1rem;
      }
      &.clickable {
        &:hover {
          background: var(--color-infoBackground);
          color: var(--color-main);
          svg {
            color: var(--color-main);
            stroke: var(--color-main);
          }
        }
      }
    }
  }
}
