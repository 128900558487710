.faq {
  padding: 0 !important;
  overflow: hidden;
  .question {
    line-height: normal;
    overflow: hidden;
    padding: 12px 15px;

    span {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0;
      padding: 0;
    }
    .chevron-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-gray1);
      aspect-ratio: 1;
      border-radius: 500px;
      padding: 8px;
    }
    &:hover {
      background: var(--color-gray1);

      .chevron-container {
        background: var(--color-gray2);
      }
    }
  }

  .answer {
    padding: 12px 20px 10px 20px;
    background: var(--color-gray1);
    opacity: 0.9;
    p,
    a,
    em {
      font-size: 1rem;
      opacity: 1;
      line-height: normal;
    }
    strong {
      font-size: 1.05rem;
    }
    em {
      font-weight: 600;
    }
    // font-style: italic;
  }
}
