#progress-bar-container {
  width: 100%;
  padding: 0;
  overflow: hidden;

  &.empty {
    border: 1px solid var(--color-gray3);
    background: none;
  }

  &:first-child {
    padding-top: 10px;
  }

  .items {
    position: relative;
    display: grid;
    gap: 25px;
    width: 100%;
    padding: 0;
    left: 0;
    right: 0;

    width: 100%;
  }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    max-width: 250px;
    text-align: center;
    padding-bottom: 3px;
    margin: auto;

    .circle {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      background: none;
      border: 2px solid var(--color-text);
      color: var(--color-text);
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      font-size: 1rem;
      font-weight: 700;
    }

    .legend {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0;
    }

    &.current {
      opacity: 1;
      border-bottom: 3px solid var(--color-main);

      .circle {
        color: var(--color-gray0);
        background: var(--color-main);
        border: 3px solid var(--color-main);
      }
    }
  }
}
