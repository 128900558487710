.bloc-loader {
  padding: 30px;
  border-radius: 30px;

  &.gray {
    background: var(--color-gray1);
    p {
      color: var(--color-text);
    }
  }
  &.info {
    background: var(--color-infoBackground);
    p {
      color: var(--color-main);
    }
  }
  &.success {
    background: var(--color-successBackground);
    p {
      color: var(--color-success);
    }
  }
  &.fail {
    background: var(--color-errorBackground);
    p {
      color: var(--color-error);
    }
  }
}
