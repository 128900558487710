@use "ui/styles/commons/commons.scss";

.badge {
  font-weight: 600;
  border-radius: 300px;
  width: unset;
  margin: 1px 0;
  align-items: center;
  user-select: none;

  @include commons.svgColor(inherit);

  ///////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////// SIZES //////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////

  @mixin badgeSize($name, $fontSize, $paddingInline, $paddingBlock) {
    &.#{$name} {
      font-size: $fontSize;
      padding: $paddingBlock $paddingInline;
    }
  }

  ////////////////////////////////////////////

  @include badgeSize($name: "small", $fontSize: 0.74rem, $paddingBlock: 4px, $paddingInline: 7px);

  ////////////////////////////////////////////

  @include badgeSize($name: "medium", $fontSize: 0.85rem, $paddingBlock: 8px, $paddingInline: 14px);

  ////////////////////////////////////////////

  @include badgeSize($name: "big", $fontSize: 0.95rem, $paddingBlock: 10px, $paddingInline: 18px);

  ////////////////////////////////////////////

  @include badgeSize($name: "large", $fontSize: 1.2rem, $paddingBlock: 15px, $paddingInline: 30px);

  //////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////// STYLES /////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  @mixin badgeStyle(
    $name,
    $color: null,
    $backgroundColor,
    $colorHover: null,
    $backgroundColorHover,
    $colorSelected: null,
    $backgroundColorSelected: null,
    $colorSelectedHover: null,
    $backgroundColorSelectedHover: null
  ) {
    &.#{$name} {
      background: $backgroundColor;
      color: $color !important;

      @include commons.svgColor($color);

      &.clickable:hover {
        background: $backgroundColorHover;
        color: $colorHover;
        @include commons.svgColor($colorHover);
      }

      &.selected {
        @if $colorSelected {
          color: $colorSelected;
          @include commons.svgColor($colorSelected);
        } @else {
          color: $colorHover;
          @include commons.svgColor($colorHover);
        }

        @if $backgroundColorSelected {
          background: $backgroundColorSelected;
        } @else {
          background: $backgroundColorHover;
        }

        &.clickable:hover {
          @if $colorSelectedHover {
            color: $colorSelectedHover;
            @include commons.svgColor($colorSelectedHover);
          } @else {
            color: $color;
            @include commons.svgColor($color);
          }

          @if $backgroundColorSelectedHover {
            background: $backgroundColorSelectedHover;
          } @else {
            background: $backgroundColor;
          }
        }
      }
    }
  }

  ////////////////////////////////////////////

  @include badgeStyle($name: "main", $color: var(--color-gray0), $backgroundColor: var(--color-active), $colorHover: var(--color-gray0), $backgroundColorHover: var(--color-main));

  ////////////////////////////////////////////

  @include badgeStyle($name: "gray2", $backgroundColor: var(--color-darken1), $backgroundColorHover: var(--color-gray3), $backgroundColorSelectedHover: var(--color-gray5));

  ////////////////////////////////////////////

  @include badgeStyle($name: "gray", $backgroundColor: var(--color-darken0), $backgroundColorHover: var(--color-darken1), $backgroundColorSelectedHover: var(--color-gray3));

  ////////////////////////////////////////////

  @include badgeStyle($name: "empty", $backgroundColor: none, $backgroundColorHover: var(--color-gray2), $backgroundColorSelectedHover: var(--color-gray3));

  &.empty {
    border: 1px solid var(--color-gray3);

    &.selected {
      border-color: var(--color-gray4);
      &.clickable:hover {
        border-color: var(--color-gray5);
      }
    }
  }

  ////////////////////////////////////////////

  @include badgeStyle(
    $name: "archived",
    $color: var(--color-warning),
    $backgroundColor: var(--color-warningBackground),
    $colorHover: var(--color-warning-hover),
    $backgroundColorHover: var(--color-warningBackground-hover)
  );

  ////////////////////////////////////////////

  @include badgeStyle(
    $name: "denied",
    $color: var(--color-error),
    $backgroundColor: var(--color-errorBackground),
    $colorHover: var(--color-error-hover),
    $backgroundColorHover: var(--color-errorBackground-hover)
  );

  ////////////////////////////////////////////

  @include badgeStyle(
    $name: "validated",
    $color: var(--color-success),
    $backgroundColor: var(--color-successBackground),
    $colorHover: var(--color-success-hover),
    $backgroundColorHover: var(--color-successBackground-hover)
  );

  ////////////////////////////////////////////

  @include badgeStyle($name: "dark", $color: var(--color-gray0), $backgroundColor: var(--color-title), $colorHover: var(--color-gray0), $backgroundColorHover: var(--color-gray24));

  ////////////////////////////////////////////

  @include badgeStyle(
    $name: "main-light",
    $color: var(--color-active),
    $backgroundColor: var(--color-account),
    $colorHover: var(--color-main),
    $backgroundColorHover: var(--color-infoBackground-hover),
    $colorSelected: var(--color-info),
    $backgroundColorSelected: var(--color-infoBackground),
    $colorSelectedHover: var(--color-info-hover),
    $backgroundColorSelectedHover: var(--color-infoBackground-hover)
  );

  ////////////////////////////////////////////

  @include badgeStyle(
    $name: "validated-plain",
    $color: var(--color-successBackground),
    $backgroundColor: var(--color-success),
    $colorHover: var(--color-successBackground-hover),
    $backgroundColorHover: var(--color-success-hover)
  );

  ////////////////////////////////////////////

  @include badgeStyle(
    $name: "denied-plain",
    $color: var(--color-errorBackground),
    $backgroundColor: var(--color-error),
    $colorHover: var(--color-errorBackground-hover),
    $backgroundColorHover: var(--color-error-hover)
  );

  ////////////////////////////////////////////

  @include badgeStyle(
    $name: "archived-plain",
    $color: var(--color-warningBackground),
    $backgroundColor: var(--color-warning),
    $colorHover: var(--color-warningBackground-hover),
    $backgroundColorHover: var(--color-warning-hover)
  );
}
