#collaborators,
#accounting-firm-collaborators {
  p {
    margin-top: 10px;
    margin-bottom: 5px;
    &:first-child {
      margin-top: 0px;
    }
  }
  .collaborators {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    height: fit-content;

    margin-left: 0;
    margin-right: 0;

    .collaborator-container {
      display: grid;
      grid-template-columns: auto 1fr;
      margin: 0px;
      text-align: left;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;
      padding: 5px;
      border-radius: 0 8px 8px 0;
      border-left: 4px solid transparent;
      .name {
        font-weight: 600;
        margin-bottom: 5px;
      }
      .user-role {
        font-style: italic;
        font-weight: 400;
        font-size: 0.85rem;
        color: var(--color-gray15);
      }
      &.selected {
        padding-left: 15px;
        background-color: var(--color-blue-light);
        border-color: var(--color-active);
        * {
          color: var(--color-active);
        }
        &:hover {
          background-color: var(--color-blue-light-hover);
        }
      }
      &:hover {
        background-color: var(--color-gray2);
      }
    }
  }

  .gestion {
    gap: 10px;
    select {
      max-width: 100%;
    }
    & .tile {
      height: fit-content;
    }

    .categories-wrapper {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, auto));
      column-gap: 25px;
      row-gap: 8px;

      .category-container {
        display: grid;
        grid-template-columns: 30px auto 40px;
        gap: 10px;
        align-items: center;
        height: 100%;
        margin: 0;
        justify-content: unset;

        h4 {
          margin: 10px;
        }
        .input-container {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  button.add {
    margin: auto;
    margin-top: 30px;
  }
  button.error {
    padding: 10px 15px;
  }
  .role-btn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  button.button {
    width: 100%;
  }

  .modal {
    .content {
      h2 {
        margin-top: 10px;
        text-align: center;
      }
      .input-container input {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
