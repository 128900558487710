#darkmode-btn {
  width: 55px;
  height: 55px;
  aspect-ratio: 1/1;
  position: relative;
  background: var(--color-gray0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  margin: 0;
  padding: 0;
  &:hover {
    background: var(--color-gray2);
  }
}
