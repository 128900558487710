.infobulle-container {
  position: relative;
  display: flex;
  width: fit-content;
  .infobulle {
    visibility: hidden;
    font-size: 0.8rem;
    white-space: break-spaces;
    background-color: var(--color-gray11);
    color: var(--color-gray0);
    border-radius: 5px;
    padding: 8px;

    position: absolute;
    z-index: 50;

    opacity: 0;
    transition: opacity 0.15s;

    max-width: 250px;
    width: max-content;

    //Position : Bottom
    right: 50%;
    bottom: 50%;
    transform: translateX(50%) translateY(calc(100% + 15px));
    &::after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent var(--color-gray11) transparent;

      bottom: 100%;
      right: calc(50% - 6px);
    }
  }
  &:hover {
    .infobulle {
      visibility: visible;
      opacity: 1;
    }
  }
}
