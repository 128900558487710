@use "./../../../styles/commons/variablesSCSS.scss";
@use "./../../../styles/commons/commons.scss";

.navlink,
.navlink-group,
.navlink-multistep {
  font-size: 0.88rem;
  font-weight: 700;
  user-select: none;
  padding: 4px 10px;
  margin: 0;
  border-radius: 3px;
  cursor: pointer;
  display: grid;
  column-gap: 10px;
  align-items: center;
  width: 100%;
  color: var(--color-gray16);
  @include commons.svgColor(var(--color-gray16));

  > div,
  > span {
    color: inherit;
  }

  &.large {
    padding: 10px;
    border-left-width: 5px;

    p {
      font-weight: 600;
    }
  }

  .count {
    background-color: var(--color-gray1);
    border-radius: 50px;
    padding: 8px;
    margin-left: 15px;
    font-size: 0.8rem;
    line-height: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    background: var(--color-gray1);
    border-radius: 50px;
    aspect-ratio: 1/1;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;

    @include commons.svgColor(var(--color-text-light));

    svg.chevron {
      opacity: 1;
      color: var(--color-gray5);
      stroke: var(--color-gray5);
    }
  }
  &.selected {
    > .icon {
      background: var(--color-active);
      color: white;
      stroke: white;
      opacity: 1;

      @include commons.svgColor(white);
    }

    // > :not(.icon) {
    //   color: white;
    //   stroke: white;
    // }
  }

  &:hover:not(.disabled, .deleted, .selected) {
    background: var(--color-account);

    .icon {
      opacity: 1;
      background: var(--color-main);

      @include commons.svgColor(white);
    }
  }

  &:last-child {
    border-bottom: none !important;
    margin-bottom: 10px;
  }
}

.navlink {
  grid-template-columns: 25px 1fr auto;

  &.company-link {
    grid-template-columns: 25px 1fr 20px;
    border-bottom: 1px solid var(--color-gray1);
    border-right: 1px solid var(--color-gray1);
    padding-right: 4px;
    text-transform: unset;

    span {
      font-size: 0.8rem;
    }

    &.noAccess {
      cursor: unset;
      background: var(--color-gray3);
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }

  &.selected {
    cursor: unset;
    border-left: 3px solid var(--color-active);
    color: var(--color-active);
    background: var(--color-blue-light);
    @include commons.svgColor(var(--color-active));

    .picture .img-container {
      background: var(--color-infoBackground);
    }

    .count {
      border-color: var(--color-active);
      background: var(--color-active);
      color: white !important;
      div {
        color: white;
      }
    }
    .circle {
      background: var(--color-active) !important;
      @include commons.svgColor(white);
    }
  }

  // CIRCLE FORM
  &.circle {
    border-radius: 300px;
    border: none;
    margin-bottom: 5px;
    padding: 10px;

    &.selected {
      background: var(--color-active) !important;
      border-radius: 300px !important;
      @include commons.svgColor(white);

      * {
        color: white;
        stroke: white;
      }
      .chevron {
        stroke: white !important;
      }
    }
  }

  &.deleted {
    background-color: var(--color-errorBackground);
    color: var(--color-error);
    &:hover {
      cursor: auto;
    }
  }

  &:hover.disabled {
    cursor: auto;
    background-color: unset;
  }
}

.navlink-group {
  padding-block: 0px;
  grid-template-columns: 1fr;
  .icon {
    opacity: 0.7;
  }
  > .navlink {
    padding-left: 0;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }
  }

  &.selected {
    .chevron {
      color: var(--color-active) !important;
    }
    .icon {
      opacity: 1;
    }
    > .navlink {
      background: var(--color-account);
      .icon {
        background: var(--color-active);
        * {
          color: white !important;
          stroke: white !important;
        }
      }
      .count {
        div {
          color: white !important;
        }
      }
    }
  }

  .navlink-column {
    border-top: 1px solid var(--color-gray1);
    padding-left: 12px;
    background: var(--color-gray1);
    .navlink {
      border-radius: 0;
      padding-block: 4px;
      border-left: 3px solid var(--color-gray3);

      &.selected {
        border-left: 3px solid var(--color-active);
        color: var(--color-active);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .legend {
    grid-template-columns: 30px 1fr 20px;
  }
}

// // MEDIA QUERY

@media (max-width: variablesSCSS.$media-tablet) {
  .navlink {
    grid-template-columns: 30px 1fr auto;

    .icon {
      width: 100%;
      height: auto;
      padding: 6px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    svg {
      width: 100%;
      height: auto;
      padding: 3px;
    }

    &.company-link {
      grid-template-columns: 40px 1fr auto;

      .picture {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}
