.error-message {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-errorBackground-hover);

  background: var(--color-errorBackground);
  color: var(--color-error);
}

.tile.gray,
.tile.gray-light {
  input,
  select,
  textarea {
    background: var(--color-gray0);
  }
}

.input-container {
  display: flex;
  padding: 0.5rem 0;
  width: 100%;
  margin: 5px 0;
  max-width: var(--size-input);
  > label {
    margin-bottom: 10px;
    cursor: pointer;
  }

  input,
  select,
  textarea {
    width: 100%;
  }
  input[type="date"],
  input[type="number"] {
    text-align: center;
  }
  fieldset {
    width: 100%;
  }
  svg {
    color: var(--color-gray17);
    // fill: var(--color-gray17);
    stroke: var(--color-gray17);
  }

  // SelectInput
}

.drop-zone-container {
  width: 100%;
  .drop-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 0.5rem;
    }
    .subtitle {
      font-size: 0.9rem;
      color: var(--color-text-light);
    }

    &.dragin {
      background-color: var(--color-gray2);
    }
  }
  .fec {
    display: flex;
    gap: 1rem;
    padding: 10px;
    border-radius: var(--size-borderRadius);
    background: var(--color-infoBackground);
    overflow: hidden;
    flex-direction: row;

    &.dragin {
      background-color: var(--color-infoBackground-hover);
    }
    svg {
      box-sizing: content-box;
      margin: 0px;
    }

    .icon {
      background-color: var(--color-main);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-gray0);
    }
    .file {
      display: flex;
      gap: 8px;
      flex-direction: column;
      justify-content: center;
      .name {
        font-size: 1.05rem;
        font-weight: 600;
      }
      .size {
        font-size: 0.9rem;
        font-weight: 700;
        color: var(--color-gray8);
      }
      .button {
        margin-top: 8px;
        padding: 8px;
        display: flex;
        gap: 5px;
        align-items: flex-end;
        width: fit-content;
        border-radius: 8px;
        &:hover {
          background-color: var(--color-gray2);
        }
      }
    }
  }
  &.img {
    padding: 2px;
    position: relative;

    &:last-of-type {
      margin-bottom: 5px;
    }
  }
  .cache {
    padding-block: 5px;
    position: absolute;
    inset: 0;
    background-color: var(--color-text);
    opacity: 0.6;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border-radius: var(--size-borderRadius);
    * {
      opacity: 1;
      color: var(--color-gray3);
    }
    p {
      font-size: 1.1rem;
      margin-block: 10px;
      font-weight: 600;
    }
    svg {
      height: 100%;
      max-height: 80px;
      width: auto;
    }
  }
}
