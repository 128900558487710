.calendarWrapperInput {
  user-select: none !important;
  padding: 6px 12px;

  .rapidSelectAside {
    .choice {
      margin: 2px 0;
      cursor: pointer;
      border-radius: 50px;
      padding: 10px 20px;
      font-size: 1rem;
      font-weight: 500;

      &:hover {
        background: var(--color-gray2);
      }
      &.selected {
        background: var(--color-active);
        color: white;
      }
    }
  }
  // //////////////////////////////////////
  .monthSwiper {
    padding: 10px;

    .monthSlide {
      text-align: center;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  // /////////////////////
  .calendarInput {
    max-width: 400px;
    margin: auto;
    padding-right: 10px;
    .header {
      opacity: 0.7;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-bottom: 10px;
    }
    .body {
      border-radius: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      aspect-ratio: 1;
      text-align: center;

      &:hover {
        background: var(--color-gray1);
      }

      &.selected {
        background: var(--color-active) !important;
        color: var(--color-white);
      }
      &.interstice {
        background: var(--color-infoBackground) !important;
      }
    }
  }
}
