#question-rapid-access {
  width: 100%;

  .question-rapid-access-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    grid-gap: 8px;
    width: 100%;

    a {
      width: 100%;

      &.rapid-access-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        height: 100% !important;
        min-height: 90px;
        width: 100%;
        gap: 10px;
        border-radius: 25px;

        svg {
          width: 30px;
          height: auto;
        }

        padding: 30px 30px 15px;

        .legend {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0;
          margin-bottom: 0rem;
          min-width: 100px;
          h3 {
            font-size: 1rem;
            margin-block: 0;
            margin-inline: auto;
          }

          .number {
            border-radius: 100px;
            padding: 16px 14px;
            min-width: 25px;
            height: 25px;
            font-size: 0.95rem;
            font-weight: 700;
            position: absolute;
            top: 8px;
            right: 8px;
            border-radius: 50px;
            background: var(--color-gray1);
            color: var(--color-text);
            justify-content: center;
            align-items: center;
            display: flex;
          }
        }
        &.tovalidated {
          svg {
            fill: var(--color-gray14);
            stroke: var(--color-gray14);
          }
          &.clickable:hover {
            border-color: var(--color-gray2);
            background: var(--color-gray2);
            .number {
              color: var(--color-gray2);
              background: var(--color-text);
            }
          }
        }
        &.tosend {
          svg {
            fill: var(--color-info);
            stroke: var(--color-info);
          }
          &.clickable:hover {
            border-color: var(--color-infoBackground);
            background: var(--color-infoBackground);
            .number {
              color: var(--color-infoBackground);
              background: var(--color-info);
            }
          }
        }
        &.pending {
          svg {
            fill: var(--color-warning);
            stroke: var(--color-warning);
          }
          &.clickable:hover {
            border-color: var(--color-warningBackground);
            background: var(--color-warningBackground);
            .number {
              color: var(--color-warningBackground);
              background: var(--color-warning);
            }
          }
        }
        &.toTreat {
          &.clickable:hover {
            border-color: var(--color-secondary);
            background: var(--color-secondary-background);
            .number {
              background: var(--color-secondary);
              color: white;
            }
          }
        }
        &.treated {
          svg {
            fill: var(--color-success);
            stroke: var(--color-success);
          }
          &.clickable:hover {
            border-color: var(--color-successBackground);
            background: var(--color-successBackground);
            .number {
              color: var(--color-successBackground);
              background: var(--color-success);
            }
          }
        }
        &.archived {
          svg {
            fill: var(--color-error);
            stroke: var(--color-error);
          }
          &.clickable:hover {
            border-color: var(--color-errorBackground);
            background: var(--color-errorBackground);
            .number {
              background: var(--color-error);
              color: white;
            }
          }
        }

        // Company
        &.toanswer {
          svg {
            fill: var(--color-info);
            stroke: var(--color-info);
          }
          &.clickable:hover {
            border-color: var(--color-infoBackground);
            background: var(--color-infoBackground);

            .number {
              background: var(--color-info);
              color: white;
            }
          }
        }
        &.tosendcompany {
          svg {
            stroke: var(--color-warning);
          }
          &.clickable:hover {
            border-color: var(--color-warningBackground);
            background: var(--color-warningBackground);

            .number {
              background: var(--color-warning);
              color: white;
            }
          }
        }
        &.denied {
          svg {
            stroke: var(--color-error);
          }
          &.clickable:hover {
            border-color: var(--color-errorBackground);
            background: var(--color-errorBackground);
            .number {
              background: var(--color-error);
              color: white;
            }
          }
        }
        &.history {
          svg {
            stroke: var(--color-success);
          }
          &.clickable:hover {
            border-color: var(--color-gray2);
            background: var(--color-successBackground);
            .number {
              background: var(--color-success);
              color: white;
            }
          }
        }
      }
    }
  }
}
